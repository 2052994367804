
import React, {Link} from 'react';
import { FunctionField, SelectField, ReferenceArrayField, ChipField, SingleFieldList, ListButton, EditButton, SimpleShowLayout,TextField, Show, useTranslate, ImageField, TopToolbar } from 'react-admin';

import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import {locationDict} from '../../util/variant'
import {Status} from '../../util/constant'
import {ValidateField, FormattedDateField} from '../../control/field/index'
import {DeleteButton, DataLockButton, DataUnlockButton} from '../../control/button/index'
import ActivateIcon from '@material-ui/icons/Check';
import NotActivateIcon from '@material-ui/icons/Clear';

const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};

// const changePassword = () => {
//     // window.location.href = '/#/change-password';
// }

const ShowActions = ({ basePath, data, resource }) => (
    // <CardActions style={cardActionStyle}>
    //     <EditButton basePath={basePath} record={data} />
    // </CardActions>
    <TopToolbar>
        <DeleteButton content="Etes-vous sur de vouloir supprimer ce message? Les utilisateurs ne le verront plus dans leur boîte de réception" redirect='list' basePath={basePath} record={data} resource={resource}/>
        <ListButton basePath={basePath}/>
        {/* {typeof data != 'undefined' &&  typeof data.is_locked != 'undefined' && data.is_locked === Status.UNLOCKED &&
            <DataLockButton basePath={basePath} record={data} resource={resource}/>
        }
        {typeof data != 'undefined' &&  typeof data.is_locked != 'undefined' && data.is_locked === Status.LOCKED &&
            <DataUnlockButton basePath={basePath} record={data} resource={resource}/>
        } */}
        {/* <EditButton basePath={basePath} record={data} />     */}
        
    </TopToolbar>
);

const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.notice.show')}: {record ? `${record.name}` : ''}</span>;
    
};

const ToAllField = ({ source, record = {}, label}) => {
    // const classes = useStyles();
    const toAllVals = record[source];
    if(typeof(toAllVals) === 'undefined' || toAllVals.length === 0)
        return <div><NotActivateIcon color='disabled'/></div>;
    return <div><ActivateIcon color='primary'/></div>;
    
};
    // <div>
    //     <span class>{record[source]}</span>
    //     <img src={record[source] == TRUE ? ActivateIcon : NotActivateIcon} 
    //         width='20' height='20'
    //         alt={''}/>
    // </div>;

ToAllField.defaultProps = {
    addLabel: true,
};

const ShowNotice = ({staticContext, ...props}) => (
    <Show title={<Title />} {...props} actions={<ShowActions />}>
            
        <SimpleShowLayout>
            <TextField source="title" label = "Titre"/>
            <TextField source="content" label = "Contenu"/>
            {/* <ToAllField source="to_all_drivers" label='Envoyer à tous les chauffeurs'/>
            <ReferenceArrayField label="Chauffeurs" reference="driver" source="driver_ids" basePath="">
                <SingleFieldList>
                    <FunctionField label="" render={record => `${record.first_name} ${record.last_name} (${record.email})`} />
                </SingleFieldList>
            </ReferenceArrayField> */}
            <ToAllField source="to_all_clients" label='Envoyer à tous les clients'/>
            <ReferenceArrayField label="Clients" reference="client" source="client_ids" basePath="" perPage={99999999}>
                <SingleFieldList>
                    <FunctionField label="" render={record => `${record.first_name} ${record.last_name} (${record.email})`} />
                </SingleFieldList>
            </ReferenceArrayField>
            <FormattedDateField source="create_time" label="Date de création"  locales="fr-FR" format="format_date_time" />
            <ValidateField source="published" label='Publié?'/>
        </SimpleShowLayout>
    </Show>
);


export default ShowNotice;