import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form'
import { change, submit, isSubmitting } from 'redux-form';
import {
    crudUpdate,
    useTranslate,
    fetchEnd,
    fetchStart,
    required,
    // redirect,
    showNotification,
    DateInput, ImageField, ImageInput,
    Button,
    ReferenceInput,
    AutocompleteInput,
    SaveButton,
    SimpleForm,
    TextInput,
    NumberInput,
    RadioButtonGroupInput,
    UPDATE, CREATE,
    FileField,
    Loading,
    REDUX_FORM_NAME
} from 'react-admin';

import RichTextInput from 'ra-input-rich-text';
import { push, refresh } from 'react-router-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
// import TextInput from 'ra-input-rich-text';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import ActionSend from '@material-ui/icons/Send';
import MessageIcon from '@material-ui/icons/Message';
import CancelIcon from '@material-ui/icons/Cancel';
import axios from 'axios';
import {BACKEND_UMS_URL} from '../../util/variant'
import {httpClient} from '../../util/function'

// import { DateInput, TimeInput} from 'react-admin-date-inputs2';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import DateFnsUtils from '@date-io/date-fns';
// import MomentUtils from 'material-ui-pickers/utils/moment-utils';
import frLocale from "date-fns/locale/fr";

// import jsonDataProvider from '../../provider/json-data-provider';
// import moment from 'moment';
// import { UB_TYPE_EXECUTIVE } from '../../util/constant';
// const dataProvider = jsonDataProvider(BACKEND_UMS_URL, httpClient);

import superDataProvider from '../../provider/super-data-provider';
import uploadFileProvider, { convertFileToBase64 } from '../../provider/upload-file-provider';
import { UB_DOC_TYPE_ADDRESS_PROOF_RECTO, UB_DOC_TYPE_ID_CARD_RECTO, UB_DOC_TYPE_ID_CARD_VERSO } from '../../util/constant';
// const dataProvider = uploadFileProvider(superDataProvider);

DateFnsUtils.prototype.getStartOfMonth = DateFnsUtils.prototype.startOfMonth;

class EventCreateButton extends Component {
    state = {
        message: "",
        error: false,
        showDialog: false,
        showLoading: false,
       
    }
    

    // fetchData = (selectedIds) => {
    //     const token = localStorage.getItem('token');
    //     const url = BACKEND_URL + '/list-doc/' + selectedIds;
    //     console.log('Get doc list url: ' + url);
    //     const request = new Request(url, {
    //         method: 'GET',
    //         headers: new Headers({ 'Content-Type': 'application/json', 
    //                                 'Authorization': `Bearer ${token}` }),
    //     })
    //     fetch(request)
    //         .then(response => {
    //             if (response.status < 200 || response.status >= 300) {
    //                 throw new Error(response.statusText);
    //             }

    //             response.json().then(json => {
    //                 console.log("Get doc list response json: " + JSON.stringify(json));
    //                 var fileArray = [];
    //                 var i = 0;
    //                 json.forEach(function(doc){
    //                     const files = doc.files;
    //                     if(files !== null && typeof files !== 'undefined'){
    //                         files.forEach(function(file){
    //                             fileArray[i] = file;
    //                             i ++;
    //                         });
    //                     }
    //                 });
                
    //                 this.setState({files: fileArray});
    //                 console.log("#Send mail of files:");
    //                 console.log(this.state.files);

    //                 this.setState({ showDialog: true, showLoading: false });
    //             });

                
    //         });
    // };

    // handleClick = () => {
    //      console.log(this.props);
    //     const selectedIds = this.props.selectedIds;
    //     var params = '';
    //     if(typeof selectedIds !== 'undefined'){
    //         selectedIds.forEach(function(selectedId) {
    //             params += selectedId + ',';
    //           });

    //         params = params.substring(0, params.length - 1);
    //     }

    //     this.fetchData(params);
    // };

    // handleCloseClick = () => {
    //     this.setState({ showDialog: false });
    // };

    // handleSaveClick = () => {
    //     const { submit } = this.props;

    //     // Trigger a submit of our custom quick create form
    //     // This is needed because our modal action buttons are oustide the form
    //     submit('send-email');
    // };

    // handleSubmit = values => {
    //     console.log('Batch email button props:');
    //     console.log(this.props);
    //     // Dispatch an action letting react-admin know a API call is ongoing
    //     this.setState({isLoading: true});

    //     console.log('Send mail values:');
    //     console.log(values);

    //     const selectedIds = this.props.selectedIds;
    //     var params = '';
    //     if(typeof selectedIds !== 'undefined'){
    //         selectedIds.forEach(function(selectedId) {
    //             params += selectedId + ',';
    //           });
    //     }

    //     if(params.length > 0)
    //         params = params.substring(0, params.length - 1);

    //     var url = BACKEND_URL + '/mails/' + params;
    //     const token = localStorage.getItem('token');

    //     axios({
    //         url: url,
    //         method: 'POST',
    //         data: values,
    //         headers: {'Authorization': `Bearer ${token}`},
            
    //       }).then((response) => {
    //             this.setState({ showDialog: false, isLoading: false });
    //       })
    //     .catch(error => showNotification(error));
      
    // }

    

    handleClick = () => {
        this.setState({ showDialog: true });
    };

    handleCloseClick = () => {
        this.setState({ showDialog: false });
    };

    handleSaveClick = () => {
        console.log('Send notice props: ' + JSON.stringify(this.props));
        const { submit, record } = this.props;

        // Trigger a submit of our custom quick create form
        // This is needed because our modal action buttons are oustide the form
        // alert('Click');
        if(record !== 'undefined' && record != null && record.id !=='undefined')
            submit(record.id);
    };

    handleSubmit = values => {
        // const { fetchStart, fetchEnd, showNotification, record } = this.props;
        // console.log('Reject doc button props: ' + this.props);
        // Dispatch an action letting react-admin know a API call is ongoing
        // fetchStart();
        const { basePath, crudUpdate, resource, refresh, record, type } = this.props;

        // As we want to know when the new post has been created in order to close the modal, we use the
        // dataProvider directly
        // values['id'] = record.id;
        // values['status'] = DocStatus.REJECTED;
        // showNotification("Error", 'error');
        
        // this.setState({ showDialog: true, error: true });

        // var plateNumber = record.plate_number;
        // console.log('Send notice button values: ' + JSON.stringify(values)); 
        // var data = {data: { 
        //     user_id: record.id,
        //     first_name: values['first_name'],
        //              last_name: values['last_name'], birth_date: values['birth_date'],
        //              address: values['address'], postal_code: values['postal_code'],
        //              city: values['city'],
        //              type: type, files:[],
        //              id_card_recto: values['id_card_recto'],
        //              id_card_verso: values['id_card_verso'],
        //               address_proof_recto: values['address_proof_recto']}};

                      var data = { 
                        // user_id: record.id,
                        first_name: values['first_name'],
                                 last_name: values['last_name'], birth_date: values['birth_date'],
                                 address: values['address'], postal_code: values['postal_code'],
                                 city: values['city'],
                                 type: type, files:[],
                                 id_card_recto: values['id_card_recto'],
                                 id_card_verso: values['id_card_verso'],
                                  address_proof_recto: values['address_proof_recto']};

                                  var files = [];
        if(values['id_card_recto']){
            data.id_card_recto.doc_type = UB_DOC_TYPE_ID_CARD_RECTO;
            files = [...files, data.id_card_recto];
        }

        if(values['id_card_verso'] != null){
            data.id_card_verso.doc_type = UB_DOC_TYPE_ID_CARD_VERSO;
            files = [...files, data.id_card_verso];
        }

        if(values['address_proof_recto'] != null){
            data.address_proof_recto.doc_type = UB_DOC_TYPE_ADDRESS_PROOF_RECTO;
            files = [...files, data.address_proof_recto];
        }

        // delete data['id_card_recto'];
        // delete data['id_card_verso'];
        // delete data['address_proof_recto'];

        data.files = files;

        console.log('Data files length = ' + data.files.length);
        console.log(data.files);
        const formerFiles = data.files.filter(p => !(p.rawFile instanceof File));
        const newFiles = data.files.filter(p => p.rawFile instanceof File);

        Promise.all(newFiles.map(convertFileToBase64))
            .then(base64Files => base64Files.map((base64, index) => ({
                src: base64,
                title: `${newFiles[index].title}`,
                doc_type: `${newFiles[index].doc_type}`,
            })))
            .then(transformedNewFiles => {
                data.files = [transformedNewFiles, formerFiles];
                crudUpdate('customer_manager', record.id, data, basePath, 'edit', true);
        });
        // crudUpdate('customer_manager', 0, data, basePath, 'edit', true);
        // this.setState({ showDialog: false });
            
        // dataProvider(CREATE, 'customer_manager', data)
        //     .then(() => {
        //         console.log("Event updated");
        //         this.setState({ showDialog: false });
        //         refresh();
        //         // window.location.replace("#/driver_company/" + record.id + "#?r=1234");
        //     })
        //     .catch(error => {
        //         console.log("Event update error: " + JSON.stringify(error));
        //         showNotification("Error", 'error');
        //         this.setState({error: true, message: "Erreur"});
        //     })
        //     .finally(() => {
        //         // Dispatch an action letting react-admin know a API call has ended
        //         fetchEnd();
        //     });

        // this.setState({ showDialog: false });
    };

    validate = values => {
        const errors = {};
        if (!values.first_name) {
            errors.first_name = ['Ce champ est requis'];
        }

        if (!values.last_name) {
            errors.last_name = ['Ce champ est requis'];
        }

        
        if (!values.birth_date) {
            errors.birth_date = ['Ce champ est requis'];
        }

        if (!values.address) {
            errors.address = ['Ce champ est requis'];
        }

        if (!values.city) {
            errors.city = ['Ce champ est requis'];
        }

         if (!values.postal_code) {
            errors.postal_code = ['Ce champ est requis'];
        }

        return errors
    };

    render() {
        const { showDialog, showLoading, error, message } = this.state;
        const { isSubmitting, plateNumber, record, title, translate } = this.props;
        
       
        const onSubmit = async values => {
            // window.alert(JSON.stringify(values, 0, 2))
            this.handleSubmit(values);
        }

        // var id = record.id;
        let submit;
        
        // const classes = useStyles();
        const uploadFileText = translate('ra.input.file.upload_single') + '(' + translate('max_size') + '5Mo)';

        return (
            <Fragment>
                <Button onClick={this.handleClick} label="Editer">
                    <EditIcon />
                </Button>
                { this.state.isLoading && 
                    <Loading loadingPrimary="" loadingSecondary="" />
                    }
                <Dialog
                    fullWidth={false}
                    maxWidth="1000xs"
                    open={showDialog}
                    onClose={this.handleCloseClick}
                    // redirect={"/driver_company/" + record.id}
                    aria-label=""
                >
                    <DialogTitle>{title}</DialogTitle>
                    <DialogContent>
                    <Form validate={this.validate}
                            toolbar={null}
                            onSubmit={onSubmit}
                            render={({ handleSubmit, form, submitting, pristine, values }) => {submit = handleSubmit
                            return (
                                <form id="event-create-form" onSubmit={handleSubmit}>
                                <div>
                                <TextInput defaultValue={record.last_name} source="last_name" label="Nom" validate={required()} style={{'width':'600px'}}/>
                                <div/>
            <TextInput defaultValue={record.first_name} source="first_name" label="Prénom" validate={required()} style={{'width':'600px'}}/>
            <div/>
            <MuiPickersUtilsProvider utils={MomentUtils}>
            {/* <DateInput source="birth_day" label="Date de naissance"
            autoComplete="" options={{ format: 'dd/MM/yyyy' }} 
                 providerOptions={{ utils: DateFnsUtils, locale: frLocale }}
            /> */}

            <DateInput source="birth_date" label="Date de naissance"
                    options={{ format: 'DD/MM/YYYY' }} defaultValue={record.birth_date}
                        providerOptions={{ utils: DateFnsUtils, locale: frLocale }}
                        validate={required()} style={{'width':'600px'}}/>


            </MuiPickersUtilsProvider>
            <div/>
            <TextInput defaultValue={record.address} source="address" label="Adresse" validate={required()} style={{'width':'600px'}}/>
            <div/>
            <TextInput defaultValue={record.postal_code} source="postal_code" label="Code postal" validate={required()} style={{'width':'600px'}}/>
            <div/>
            <TextInput defaultValue={record.city} source="city" label="Ville" validate={required()} style={{'width':'600px'}}/>
            <div/>
            {/* <FormDataConsumer>
                {({ formData, ...rest }) => formData.user_type === USER_TYPE_COMPANY_AND_DRIVER &&
                <RadioButtonGroupInput source="manager_sub_type" label="Rôle dans la société" choices={[
                 { id: UB_TYPE_EXECUTIVE, name: 'Cadre' }, { id: UB_TYPE_OWNER, name: "Associé (si les parts >= 25%" },
            ]} validate={required()} />
                     
                }
            </FormDataConsumer>
 */}
            <ImageInput defaultValue={record.id_card_recto} source="id_card_recto" label="Pièce d'identité recto" accept="image/*" maxSize='5000000' placeholder={uploadFileText}>
                <ImageField source="url" title="Fichier" />
            </ImageInput>

            <ImageInput defaultValue={record.id_card_verso} source="id_card_verso" label="Pièce d'identité verso" accept="image/*" maxSize='5000000' placeholder={uploadFileText}>
                <ImageField source="url" title="Fichier" />
            </ImageInput>


            <ImageInput defaultValue={record.address_proof_recto} source="address_proof_recto" label="Justificatif de domicile" accept="image/*" maxSize='5000000' placeholder={uploadFileText}>
                <ImageField source="url" title="Fichier" />
            </ImageInput>

           
                                </div>
                                
                                {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
                                </form>
                            )
                            }}
                    >
                <span>{this.state.message}</span>
                        
                    </Form>

                        
                    </DialogContent>
                    <DialogActions>
                        {/* <SaveButton
                            saving={isSubmitting}
                            onClick={this.handleSaveClick}
                        /> */}
                       
                        
                        <Button label="ra.action.cancel" onClick={this.handleCloseClick}>
                            <CancelIcon color="primary"/>
                        </Button>
                        <Button label="Sauvegarder" onClick={() =>
                                // { cancelable: true } required for Firefox
                                // https://github.com/facebook/react/issues/12639#issuecomment-382519193
                                document
                                .getElementById('event-create-form')
                                .dispatchEvent(new Event('submit', { cancelable: true }))
                            } saving={isSubmitting}>
                            <SaveIcon color="primary"/>
                        </Button>
                    </DialogActions>
                </Dialog>
            </Fragment>
        );
    }
}

EventCreateButton.propTypes = {
    label: PropTypes.string,
    push: PropTypes.func,
    refresh: PropTypes.func,
    record: PropTypes.object,
    showNotification: PropTypes.func,
};

const mapStateToProps = state => ({
    isSubmitting: isSubmitting("event-create-form"),
});
const  mapDispatchToProps= {
    change,
    // redirect,
    refresh,
    fetchEnd,
    fetchStart,
    showNotification,
    submit
};

export default connect(undefined, { crudUpdate })(EventCreateButton);

// export default connect(mapStateToProps, mapDispatchToProps)(
//     EventCreateButton
// );

//------------------------
// import React, { Component, Fragment } from 'react';
// import PropTypes from 'prop-types';
// import { connect } from 'react-redux';

// import { Form, Field } from 'react-final-form'
// import { change, submit, isSubmitting } from 'redux-form';
// import {
//     crudUpdate,
//     fetchEnd,
//     fetchStart,
//     required,
//     showNotification,
//     crudGetMatching,
//     Button,
//     SaveButton,
//     ReferenceInput,
//     AutocompleteInput,
    
//     SimpleForm,
//     TextInput,
//     NumberInput,
//     RadioButtonGroupInput,
//     CREATE,
//     FileField,
//     withDataProvider,
//     Loading,
//     REDUX_FORM_NAME
// } from 'react-admin';

// import RichTextInput from 'ra-input-rich-text';
// import { push } from 'react-router-redux';
// import Dialog from '@material-ui/core/Dialog';
// import DialogTitle from '@material-ui/core/DialogTitle';
// import DialogContent from '@material-ui/core/DialogContent';
// import DialogActions from '@material-ui/core/DialogActions';
// // import TextInput from 'ra-input-rich-text';
// import AddIcon from '@material-ui/icons/Add';
// import SaveIcon from '@material-ui/icons/Save';
// import ActionSend from '@material-ui/icons/Send';
// import MessageIcon from '@material-ui/icons/Message';
// import CancelIcon from '@material-ui/icons/Cancel';
// import axios from 'axios';
// import {BACKEND_UMS_URL} from '../../util/variant'
// import {httpClient} from '../../util/function'

// import { DateInput, TimeInput} from 'react-admin-date-inputs2';
// import { MuiPickersUtilsProvider } from "@material-ui/pickers";
// import MomentUtils from '@date-io/moment';
// import DateFnsUtils from '@date-io/date-fns';
// // import MomentUtils from 'material-ui-pickers/utils/moment-utils';
// import frLocale from "date-fns/locale/fr";

// import jsonDataProvider from '../../provider/json-data-provider';
// import moment from 'moment';
// const dataProvider = jsonDataProvider(BACKEND_UMS_URL, httpClient);

// DateFnsUtils.prototype.getStartOfMonth = DateFnsUtils.prototype.startOfMonth;

// class EventCreateButton extends Component {
//     state = {
//         message: "abc",
//         error: false,
//         showDialog: false,
//         showLoading: false,
       
//     }
    

//     // fetchData = (selectedIds) => {
//     //     const token = localStorage.getItem('token');
//     //     const url = BACKEND_URL + '/list-doc/' + selectedIds;
//     //     console.log('Get doc list url: ' + url);
//     //     const request = new Request(url, {
//     //         method: 'GET',
//     //         headers: new Headers({ 'Content-Type': 'application/json', 
//     //                                 'Authorization': `Bearer ${token}` }),
//     //     })
//     //     fetch(request)
//     //         .then(response => {
//     //             if (response.status < 200 || response.status >= 300) {
//     //                 throw new Error(response.statusText);
//     //             }

//     //             response.json().then(json => {
//     //                 console.log("Get doc list response json: " + JSON.stringify(json));
//     //                 var fileArray = [];
//     //                 var i = 0;
//     //                 json.forEach(function(doc){
//     //                     const files = doc.files;
//     //                     if(files !== null && typeof files !== 'undefined'){
//     //                         files.forEach(function(file){
//     //                             fileArray[i] = file;
//     //                             i ++;
//     //                         });
//     //                     }
//     //                 });
                
//     //                 this.setState({files: fileArray});
//     //                 console.log("#Send mail of files:");
//     //                 console.log(this.state.files);

//     //                 this.setState({ showDialog: true, showLoading: false });
//     //             });

                
//     //         });
//     // };

//     // handleClick = () => {
//     //      console.log(this.props);
//     //     const selectedIds = this.props.selectedIds;
//     //     var params = '';
//     //     if(typeof selectedIds !== 'undefined'){
//     //         selectedIds.forEach(function(selectedId) {
//     //             params += selectedId + ',';
//     //           });

//     //         params = params.substring(0, params.length - 1);
//     //     }

//     //     this.fetchData(params);
//     // };

//     // handleCloseClick = () => {
//     //     this.setState({ showDialog: false });
//     // };

//     // handleSaveClick = () => {
//     //     const { submit } = this.props;

//     //     // Trigger a submit of our custom quick create form
//     //     // This is needed because our modal action buttons are oustide the form
//     //     submit('send-email');
//     // };

//     // handleSubmit = values => {
//     //     console.log('Batch email button props:');
//     //     console.log(this.props);
//     //     // Dispatch an action letting react-admin know a API call is ongoing
//     //     this.setState({isLoading: true});

//     //     console.log('Send mail values:');
//     //     console.log(values);

//     //     const selectedIds = this.props.selectedIds;
//     //     var params = '';
//     //     if(typeof selectedIds !== 'undefined'){
//     //         selectedIds.forEach(function(selectedId) {
//     //             params += selectedId + ',';
//     //           });
//     //     }

//     //     if(params.length > 0)
//     //         params = params.substring(0, params.length - 1);

//     //     var url = BACKEND_URL + '/mails/' + params;
//     //     const token = localStorage.getItem('token');

//     //     axios({
//     //         url: url,
//     //         method: 'POST',
//     //         data: values,
//     //         headers: {'Authorization': `Bearer ${token}`},
            
//     //       }).then((response) => {
//     //             this.setState({ showDialog: false, isLoading: false });
//     //       })
//     //     .catch(error => showNotification(error));
      
//     // }

    

//     handleClick = () => {
//         this.setState({ showDialog: true });
//     };

//     handleCloseClick = () => {
//         this.setState({ showDialog: false });
//     };

//     handleSaveClick = () => {
//         console.log('Send notice props: ' + JSON.stringify(this.props));
//         const { submit, record } = this.props;

//         // Trigger a submit of our custom quick create form
//         // This is needed because our modal action buttons are oustide the form
//         // alert('Click');
//         submit(record.id);
//     };

//     handleSubmit = values => {
//         // const { fetchStart, fetchEnd, showNotification, record } = this.props;
//         // console.log('Reject doc button props: ' + this.props);
//         // Dispatch an action letting react-admin know a API call is ongoing
//         fetchStart();
//         const { basePath, resource, record } = this.props;
//         const { dataProvider, dispatch, redirect } = this.props;

//         // As we want to know when the new post has been created in order to close the modal, we use the
//         // dataProvider directly
//         // values['id'] = record.id;
//         // values['status'] = DocStatus.REJECTED;
//         // showNotification("Error", 'error');
        
//         // this.setState({ showDialog: true, error: true });

//         // console.log('Send notice button values: ' + JSON.stringify(values)); 
//         // var data = {data: { vehicle_id: record.id, event_date: values['event_date'],
//         //              start_time: values['start_time'], end_time: values['end_time'],
//         //              start_km: values['start_km'], end_km: values['end_km'],
//         //              driver_id: values['driver_id'],
//         //              comment: values['comment'], revision: values['revision'],}};
//         // var data = { ...values, vehicle_id: record.id};
//         var data = { vehicle_id: record.id, event_date: values['event_date'],
//                      start_time: values['start_time'], end_time: values['end_time'],
//                      start_km: values['start_km'], end_km: values['end_km'],
//                      driver_id: values['driver_id'],
//                      comment: values['comment'], revision: values['revision'],};

//         // , event_date: values['event_date'],
//         //              start_time: values['start_time'], end_time: values['end_time'],
//         //              start_km: values['start_km'], end_km: values['end_km'],
//         //              driver_id: values['driver_id'],
//         //              comment: values['comment'], revision: values['revision'],};
//         console.log("Create vehicle event " + JSON.stringify(data));
//         crudUpdate('vehicle_event', 0, data, basePath, 'show', true);
//         this.setState({ showDialog: false });

//         // dataProvider(CREATE, 'vehicle_event', data)
//         //     .then(({data}) => {
//         //         // crudGetMatching(
//         //         //     'vehicle_event',
//         //         //     'vehicle@vehicle_event',
//         //         //     { page: 1, perPage: 99999 },
//         //         //     { field: 'id', order: 'DESC' },
//         //         //     {}
//         //         // );
                
//         //         console.log("Event created " + JSON.stringify(data));
//         //         // change(REDUX_FORM_NAME, 'vehicle_event', data.id);
//         //         // push('/vehicle/' + vehicleId + '/show');
//         //         this.setState({ showDialog: false });
//         //         // push('/vehicle');
//         //         dispatch(push('/vehicle'));
//         //         // redirect("/show");
//         //     })
//         //     .catch(error => {
//         //         console.log("Event create error: " + JSON.stringify(error));
//         //         showNotification("Error", 'error');
//         //         this.setState({error: true, message: "Erreur"});
//         //     })
//         //     .finally(() => {
//         //         // Dispatch an action letting react-admin know a API call has ended
//         //         fetchEnd();
//         //     });

//         // this.setState({ showDialog: false });
//         // push('/vehicle');
//     };

//     validateCreation = values => {
//         const errors = {};
//         if (!values.driver_id) {
//             errors.driver_id = ['Ce champ est requis'];
//         }

//         if (!values.event_date) {
//             errors.event_date = ['Ce champ est requis'];
//         }

//         if (!values.start_time) {
//             errors.start_time = ['Ce champ est requis'];
//         }

//         // if (!values.end_time) {
//         //     errors.end_time = ['Ce champ est requis'];
//         // }

//         // if (values.start_time && values.end_time && moment(values.end_time).isBefore(moment(values.start_km))) {
//         //     errors.start_time = ["L'heure de départ doit être avant l'heure de départ"];
//         //     errors.end_time = ["L'heure de retour doit être après l'heure de départ"];
//         // }

//         if (!values.start_km) {
//             errors.start_km = ['Ce champ est requis'];
//         }

//         // if (!values.end_km) {
//         //     errors.end_km = ['Ce champ est requis'];
//         // }

//         // if (values.start_km && values.end_km && values.start_km > values.end_km) {
//         //     errors.end_km = ['Le kilomérage de retour doit être plus ou équal que le kimétrage de retour'];
//         //     errors.start_km = ['Le kilomérage de départ doit être moins ou équal que le kimétrage de retour'];
//         // }

//         // if (!values.age) {
//         //     errors.age = ['The age is required'];
//         // } else if (values.age < 18) {
//         //     errors.age = ['Must be over 18'];
//         // }
//         return errors
//     };

//     render() {
//         const { showDialog, showLoading, error, message } = this.state;
//         const { isSubmitting, record } = this.props;
        
       
//         const onSubmit = async values => {
//             // window.alert(JSON.stringify(values, 0, 2))
//             this.handleSubmit(values);
//         }

//         // var id = record.id;
//         let submit;

//         return (
//             <Fragment>
//                 <Button onClick={this.handleClick} label='Créer un événement'>
//                     <AddIcon color="primary"/>
                    
//                 </Button>
//                 { this.state.isLoading && 
//                     <Loading loadingPrimary="" loadingSecondary="" />
//                     }
//                 <Dialog
//                     fullWidth={true}
//                     maxWidth="1000xs"
//                     open={showDialog}
//                     onClose={this.handleCloseClick}
//                     aria-label="Envoie d'un message"
//                 >
//                     <DialogTitle>Créer un événément</DialogTitle>
//                     <DialogContent>
//                     <Form validate={this.validateCreation}
//                             toolbar={null}
//                             onSubmit={onSubmit}
//                             render={({ handleSubmit, form, submitting, pristine, values }) => {submit = handleSubmit
//                             return (
//                                 <form id="event-create-form" onSubmit={handleSubmit}>
//                                 <div>
//                                 <span>Numéro de véhicule : {record.plate_number}</span>

//                                 <ReferenceInput label="Chauffeur assigné" reference="driver" source="driver_id"
//                                 perPage={999999}>
//                             <AutocompleteInput 
                            
//                             options={{
//                                 suggestionsContainerProps: {
//                                     style: {
//                                     zIndex: 99999
//                                     }
//                                 }
//                                 }}
//                             optionText="user_code"/>
//                         </ReferenceInput>

//                         <MuiPickersUtilsProvider utils={MomentUtils}>
//             <DateInput source="event_date"  label="&nbsp;&nbsp;Date"
//             style={{'width':'800px'}} 
//             autoComplete="" options={{ format: '  DD/MM/yyyy' }} 
//                  providerOptions={{ utils: DateFnsUtils, locale: frLocale }}
//             />
//             </MuiPickersUtilsProvider>
            
//             <MuiPickersUtilsProvider utils={MomentUtils}>

//                 <TimeInput source="start_time" label="&nbsp;&nbsp;Heure de départ" 
//                     options={{ format: '  HH:mm' }}/>
                
//             </MuiPickersUtilsProvider>

//             <NumberInput source="start_km" label="Kilométrage du départ (km)"  style={{'width':'300px'}}/>

//             <MuiPickersUtilsProvider utils={MomentUtils}>

                
//                 <TimeInput source="end_time" label="&nbsp;&nbsp;Heure de retour" 
//                     options={{ format: '  HH:mm' }}/>

//             </MuiPickersUtilsProvider>

//             <NumberInput source="end_km" label="Kilométrage du retour (km)" style={{'width':'300px'}}/>

//             <RichTextInput multiline source="comment" label="Commentaire" fullWidth={true}/>
            
//             <RadioButtonGroupInput source="revision" label="Révision nécessaire ?" 
//             choices={[
//                 { id: '0', name: 'Non' },
//                 { id: '1', name: 'Oui' },
//             ]} initialValue='0'/>
//                                 </div>
                                
//                                 {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
//                                 </form>
//                             )
//                             }}
//                     >
//                 <span>{this.state.message}</span>
                        
//                     </Form>

                        
//                     </DialogContent>
//                     <DialogActions>
//                         {/* <SaveButton
//                             saving={isSubmitting}
//                             onClick={this.handleSaveClick}
//                         /> */}
                       
                        
//                         <Button label="ra.action.cancel" onClick={this.handleCloseClick}>
//                             <CancelIcon color="primary"/>
//                         </Button>
//                         <Button label="Créer" onClick={() =>
                                
//                                 document
//                                 .getElementById('event-create-form')
//                                 .dispatchEvent(new Event('submit', { cancelable: true }))
//                             } saving={isSubmitting}>
                            
//                             <SaveIcon color="primary"/>
//                         </Button>
//                     </DialogActions>
//                 </Dialog>
//             </Fragment>
//         );
//     }
// }

// const mapStateToProps = state => ({
//     isSubmitting: isSubmitting('notice-send-form')(state)
// });

// EventCreateButton.propTypes = {
//     label: PropTypes.string,
//     push: PropTypes.func,
//     record: PropTypes.object,
//     showNotification: PropTypes.func,
//     dataProvider: PropTypes.func.isRequired,
//     dispatch: PropTypes.func.isRequired,
//     redirect: PropTypes.func,
// };


// const mapDispatchToProps = {
//     change,
//     fetchEnd,
//     fetchStart,
//     showNotification,
//     crudGetMatching,
//     // crudCreate,
    
//     push,
//     submit,
// };

// // export default connect(mapStateToProps, mapDispatchToProps)(
// //     EventCreateButton
// // );
// export default connect(undefined, { crudUpdate })(EventCreateButton);

// // export default connect(null, {
// //     showNotification,
// //     push,
// // })(EventCreateButton);
// // export default withDataProvider(EventCreateButton)