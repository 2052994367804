import React, { Component } from 'react';
import { GET_LIST } from 'react-admin';
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';
import Card from '@material-ui/core/Card';
import { withStyles } from '@material-ui/core/styles';
import Pusher from 'pusher-js';
import {PUSHER_API_KEY, PUSHER_CLUSTER, GOOGLE_API_KEY,
  PUSHER_CHANNEL_USER, PUSHER_CHANNEL_LOCATION, EVENT_DRIVERS_LOCATION_CHANGED,
} from '../../util/variant'

import superDataProvider from '../../provider/super-data-provider';
import uploadFileProvider from '../../provider/upload-file-provider';
const dataProvider = uploadFileProvider(superDataProvider);

var markers = [];
// var map;

const mapStyles = {
    width: '100%',
    height: '100%',
        position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  };

 

// const useStyles = makeStyles({
//   map: {
//     position: 'absolute',
//     top: 0,
//     left: 0,
//     right: 0,
//     bottom: 0,
//   },
// });

class DriverMap extends Component{
  
  constructor(props) {
    super(props)
    this.onMarkerClick=this.onMarkerClick.bind(this);  
    this.map = React.createRef();
  }

  state = {
    // lat: 48.85837713793118, 
    // lng: 2.2944705696988925,
    lat: 48.85770377296783, 
    lng: 2.352252565813119,
    isMarkerShown: false,
    onlineDrivers:[],
    showingInfoWindow: false,
    activeMarker: {},
    map: null,
  };

  initPushers(){
      console.log('Init pushers');
      const onlineDriverPusher = new Pusher(PUSHER_API_KEY, {
          cluster: PUSHER_CLUSTER,
          encrypted: true
        });
        const channel = onlineDriverPusher.subscribe(PUSHER_CHANNEL_LOCATION);
        channel.bind(EVENT_DRIVERS_LOCATION_CHANGED, data => {
          console.log('Online drivers changed');
          if(localStorage.getItem('session_id'))
              this.fetchData();
        });
  }

  componentDidUpdate(prevProps) {
      // handle refresh
      if (this.props.version !== prevProps.version) {
          this.fetchData();
      }
  }

  async fetchData() {
      if(localStorage.getItem('session_id')){
        const { data: onlineDrivers } = await dataProvider(
            GET_LIST,
            'driver_online',
            {
                filter: {
                },
                sort: {},
                pagination: { page: 1, perPage: 10000 },
            }
        );
    
        console.log('Fetch online drivers: ' + JSON.stringify(onlineDrivers));
        this.setState({
            onlineDrivers: onlineDrivers,
        });

        // this.clearMarkers(markers);
        // this.drawDrivers();
      }
  }

  showCurrentLocation(){
    if ("geolocation" in navigator) {
      let self = this;
      console.log("Map: Navigator geolocation is available");
      navigator.geolocation.getCurrentPosition(function(position) {
        console.log("Map: Latitude is :", position.coords.latitude);
        console.log("Map: Longitude is :", position.coords.longitude);
        self.setState(
          {lat: position.coords.latitude, lng: position.coords.longitude, isMarkerShown: true}
          );
      });
    }
    else {
      console.log("Navigator geolocation is NOT available");
    }
  }

  componentDidMount() {
    this.fetchData();
    this.initPushers();
    this.showCurrentLocation();
  }

  onMarkerClick(props, marker, e) {
    // alert('clock');
    this.setState({
      activeMarker: marker,
      showingInfoWindow: true
    });
  }

  onMapClicked = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      })
    }
  };

  onMapReady = (mapProps, map) => {
    console.log('onMapReady');
    this.setState({map: map});
    this.fetchData();
  }

  createMarker(onlineDriver, map) {
    const position = {lat: parseFloat(onlineDriver.current_lat), 
      lng: parseFloat(onlineDriver.current_lng)};
    const icon = {
      url:onlineDriver.vehicle_map_icon_web.url,
      // url: "https://www.robotwoods.com/dev/misc/bluecircle.png",
      scaledSize: new this.props.google.maps.Size(36, 36)
      };

    const title =  onlineDriver.first_name + ' - ' + onlineDriver.last_name;

    return new window.google.maps.Marker({
      position: position,
      map: map,
      icon: icon,
      title: title,
    });
}

  clearMarkers(markers) {
    markers.map(marker => {
      marker.setMap(null);
      return null;
    });
    markers = [];
  }

  drawDrivers(){
    console.log('Draw drivers: ' + this.state.onlineDrivers.length);
    if(this.state.map != null){
      console.log('Map ist not null');
      this.state.onlineDrivers.map(onlineDriver => {
        const marker = this.createMarker(onlineDriver, this.state.map);
        markers.push(marker);
        return marker;
      });
    }
    else
      console.log('Map ist null');
    
  }
  
    render() {
      console.log('Render map');
      // map = this.refs.map;
      this.clearMarkers(markers);
      this.drawDrivers();
      
        return (
          
            <Map
            style={mapStyles}
              ref={this.map}
              onReady={ this.onMapReady } 
              onClick={this.onMapClicked}
              google={this.props.google}
              zoom={14}
              center={{
                lat: this.state.lat,
                lng: this.state.lng
              }}>
        
                <Marker 
                  lat={this.state.lat} 
                  lng={this.state.lng}
                  icon={{url:"https://www.robotwoods.com/dev/misc/bluecircle.png"}}
                />

                
                
            </Map>
            // <Card style={mapStyles}>
          // </Card>
          
        );
      }
}
// initialCenter={{ lat: 47.444, lng: -122.176}}
export default GoogleApiWrapper({
    apiKey: GOOGLE_API_KEY,
    language: 'fr',
  })(DriverMap);


  // {this.state.onlineDrivers.map(onlineDriver => {
  //   return <Marker 

  //       onClick={this.onMarkerClick}
  //       name={onlineDriver.first_name}
  //       title={onlineDriver.first_name + ' - ' + onlineDriver.vehicle_type_name}
  //       lat={onlineDriver.current_lat} 
  //       lng={onlineDriver.current_lng}
  //       icon={{
  //         url:onlineDriver.vehicle_map_icon_web.url,
  //         scaledSize: new this.props.google.maps.Size(36, 36)
  //         }}
  //   >
  //    <InfoWindow
  //     marker={this.state.activeMarker}
  //     visible={this.state.showingInfoWindow}>
  //       <div>
  //         <h1>{onlineDriver.first_name}</h1>
  //         <h2>{onlineDriver.vehicle_type_name}</h2>
  //       </div>
  //     </InfoWindow>
  // </Marker>
  
  // })}
// import React, { Component } from 'react';
// import { GET_LIST } from 'react-admin';
// import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';
// import Card from '@material-ui/core/Card';
// import { withStyles } from '@material-ui/core/styles';
// import Pusher from 'pusher-js';
// import {PUSHER_API_KEY, PUSHER_CLUSTER, GOOGLE_API_KEY,
//   PUSHER_CHANNEL_USER, PUSHER_CHANNEL_LOCATION, EVENT_DRIVERS_LOCATION_CHANGED,
// } from '../../util/variant'

// import superDataProvider from '../../provider/super-data-provider';
// import uploadFileProvider from '../../provider/upload-file-provider';
// const dataProvider = uploadFileProvider(superDataProvider);

// var markers = [];
// // var map;

// const mapStyles = {
//     width: '100%',
//     height: '100%',
//         position: 'absolute',
//     top: 0,
//     left: 0,
//     right: 0,
//     bottom: 0,
//   };

 

// // const useStyles = makeStyles({
// //   map: {
// //     position: 'absolute',
// //     top: 0,
// //     left: 0,
// //     right: 0,
// //     bottom: 0,
// //   },
// // });

// class DriverMap extends Component{
  
//   constructor(props) {
//     super(props)
//     this.onMarkerClick=this.onMarkerClick.bind(this);  
//     this.map = React.createRef();
//   }

//   state = {
//     lat: 48.86570291821893, 
//     lng: 2.320594611830285,
    
//     isMarkerShown: false,
//     onlineDrivers:[],
//     showingInfoWindow: false,
//     activeMarker: {},
//     map: null,
//   };

//   initPushers(){
//       console.log('Init pushers');
//       const onlineDriverPusher = new Pusher(PUSHER_API_KEY, {
//           cluster: PUSHER_CLUSTER,
//           encrypted: true
//         });
//         const channel = onlineDriverPusher.subscribe(PUSHER_CHANNEL_LOCATION);
//         channel.bind(EVENT_DRIVERS_LOCATION_CHANGED, data => {
//           console.log('Online drivers changed');
//           if(localStorage.getItem('session_id'))
//               this.fetchData();
//         });
//   }

//   componentDidUpdate(prevProps) {
//       // handle refresh
//       if (this.props.version !== prevProps.version) {
//           this.fetchData();
//       }
//   }

//   async fetchData() {
//       if(localStorage.getItem('session_id')){
//         const { data: onlineDrivers } = await dataProvider(
//             GET_LIST,
//             'driver_online',
//             {
//                 filter: {
//                 },
//                 sort: {},
//                 pagination: { page: 1, perPage: 10000 },
//             }
//         );
    
//         console.log('Fetch online drivers: ' + JSON.stringify(onlineDrivers));
//         this.setState({
//             onlineDrivers: onlineDrivers,
//         });

//         // this.clearMarkers(markers);
//         // this.drawDrivers();
//       }
//   }

//   showCurrentLocation(){
//     if ("geolocation" in navigator) {
//       let self = this;
//       console.log("Map: Navigator geolocation is available");
//       navigator.geolocation.getCurrentPosition(function(position) {
//         console.log("Map: Latitude is :", position.coords.latitude);
//         console.log("Map: Longitude is :", position.coords.longitude);
//         self.setState(
//           {lat: position.coords.latitude, lng: position.coords.longitude, isMarkerShown: true}
//           );
//       });
//     }
//     else {
//       console.log("Navigator geolocation is NOT available");
//     }
//   }

//   componentDidMount() {
//     this.fetchData();
//     this.initPushers();
//     this.showCurrentLocation();
//   }

//   onMarkerClick(props, marker, e) {
//     // alert('clock');
//     this.setState({
//       activeMarker: marker,
//       showingInfoWindow: true
//     });
//   }

//   onMapClicked = (props) => {
//     if (this.state.showingInfoWindow) {
//       this.setState({
//         showingInfoWindow: false,
//         activeMarker: null
//       })
//     }
//   };

//   onMapReady = (mapProps, map) => {
//     console.log('onMapReady');
//     this.setState({map: map});
//     this.fetchData();
//   }

//   createMarker(onlineDriver, map) {
//     const position = {lat: parseFloat(onlineDriver.current_lat), 
//       lng: parseFloat(onlineDriver.current_lng)};
//     const icon = {
//       url:onlineDriver.vehicle_map_icon_web.url,
//       scaledSize: new this.props.google.maps.Size(36, 36)
//       };

//     const title =  onlineDriver.first_name + ' - ' + onlineDriver.last_name;

//     return new window.google.maps.Marker({
//       position: position,
//       map: map,
//       icon: icon,
//       title: title,
//     });
// }

//   clearMarkers(markers) {
//     markers.map(marker => {
//       marker.setMap(null);
//       return null;
//     });
//     markers = [];
//   }

//   drawDrivers(){
//     console.log('Draw drivers: ' + this.state.onlineDrivers.length);
//     if(this.state.map != null){
//       console.log('Map ist not null');
//       this.state.onlineDrivers.map(onlineDriver => {
//         const marker = this.createMarker(onlineDriver, this.state.map);
//         markers.push(marker);
//         return marker;
//       });
//     }
//     else
//       console.log('Map ist null');
    
//   }
  
//     render() {
//       console.log('Render map');
//       // map = this.refs.map;
//       this.clearMarkers(markers);
//       this.drawDrivers();
      
//         return (
          
//             <Map
//             style={mapStyles}
//               ref={this.map}
//               onReady={ this.onMapReady } 
//               onClick={this.onMapClicked}
//               google={this.props.google}
//               zoom={12}
//               center={{
//                 lat: this.state.lat,
//                 lng: this.state.lng
//               }}>
        
//                 <Marker 
//                   lat={this.state.lat} 
//                   lng={this.state.lng}
//                   icon={{url:"https://www.robotwoods.com/dev/misc/bluecircle.png"}}
//                 />

                
                
//             </Map>
//             // <Card style={mapStyles}>
//           // </Card>
          
//         );
//       }
// }
// // initialCenter={{ lat: 47.444, lng: -122.176}}
// export default GoogleApiWrapper({
//     apiKey: GOOGLE_API_KEY,
//     language: 'fr',
//   })(DriverMap);


//   // {this.state.onlineDrivers.map(onlineDriver => {
//   //   return <Marker 

//   //       onClick={this.onMarkerClick}
//   //       name={onlineDriver.first_name}
//   //       title={onlineDriver.first_name + ' - ' + onlineDriver.vehicle_type_name}
//   //       lat={onlineDriver.current_lat} 
//   //       lng={onlineDriver.current_lng}
//   //       icon={{
//   //         url:onlineDriver.vehicle_map_icon_web.url,
//   //         scaledSize: new this.props.google.maps.Size(36, 36)
//   //         }}
//   //   >
//   //    <InfoWindow
//   //     marker={this.state.activeMarker}
//   //     visible={this.state.showingInfoWindow}>
//   //       <div>
//   //         <h1>{onlineDriver.first_name}</h1>
//   //         <h2>{onlineDriver.vehicle_type_name}</h2>
//   //       </div>
//   //     </InfoWindow>
//   // </Marker>
  
//   // })}