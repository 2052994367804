
import React from 'react';
import { email, DateInput, PasswordInput, FormDataConsumer, ImageInput,ImageField, TextInput, Create, RadioButtonGroupInput, SimpleForm, required, useTranslate} from 'react-admin';
import Typography from '@material-ui/core/Typography';
// import { DateInput, DateTimeInput } from 'react-admin-date-inputs2';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
// import MomentUtils from 'material-ui-pickers/utils/moment-utils';
import frLocale from "date-fns/locale/fr";
// import {countries} from '../../util/constant';
// import {locationTypes} from '../../util/variant';
import MomentUtils from '@date-io/moment';
import { UB_TYPE_EXECUTIVE, UB_TYPE_OWNER, USER_TYPE_AUTO_ENTERPRENER, USER_TYPE_COMPANY_AND_DRIVER } from '../../util/constant';
DateFnsUtils.prototype.getStartOfMonth = DateFnsUtils.prototype.startOfMonth

// import Strings from '../res/strings';
const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.driver_company.list')}</span>;
};

const validateConfirmPassword = (value, allValues) => {
    if (value !== allValues.password) {
        return 'Mots de passe ne sont pas identiques';
    }
    return [];
};

// const validateMobileNumber = (value, allValues) => {
//     if (!value.startsWith('33') || value.length !== 12) {
//         return "Doit être 12 chiffres sous forme '330xxxxxxxxx'";
//     }
//     return [];
// };
const validateMobileNumber = (value, allValues) => {
    if (!value.startsWith('33')) {
        return "Doit être sous forme '33xxxxxxxxxx'";
    }
    // if (value.length !== 10) {
    //     return "Doit être 10 chiffres";
    // }
    return [];
};

const validatePassword = [required(), validateConfirmPassword];
const validateEmail = [required(), email()];

const CreateDriver = props => {
    const translate = useTranslate();
    // const classes = useStyles();
    const uploadFileText = translate('ra.input.file.upload_single') + '(' + translate('max_size') + '5Mo)';
    return <Create title={<Title/>} {...props}>
        <SimpleForm redirect="list">
        <Typography style={{ fontWeight: 600, fontSize:15 }}>Infos de la société</Typography>
            <TextInput source="company_name" label="Raison sociale" validate={required()} style={{'width':'600px'}}/>
            <TextInput source="company_address" label="Adresse" validate={required()} style={{'width':'600px'}}/>
            <TextInput source="postal_code" label="Code postal" validate={required()}/>
            <TextInput source="city" label="Ville" validate={required()}/>
            <TextInput source="mobile_dial_code" label="Indicatif du pays (E.x 33)" defaultValue={'33'} validate={required()} style={{'width':'600px'}}/>
            <TextInput source="mobile" label="Téléphone" validate={required()}/>
            <TextInput source="email" label="Email" validate={validateEmail} style={{'width':'600px'}}/>
            
            <RadioButtonGroupInput source="user_type" label="Type de société" choices={[
                 { id: USER_TYPE_AUTO_ENTERPRENER, name: 'Auto-entrepreneur' }, { id: USER_TYPE_COMPANY_AND_DRIVER, name: "Autre" },
            ]} validate={required()} />

            <TextInput source="siren_number" label="Numéro de SIRET" validate={required()}/>
            
            <FormDataConsumer>
                {({ formData, ...rest }) => formData.user_type === USER_TYPE_COMPANY_AND_DRIVER &&
                <TextInput source="vat_no" label="Numéro TVA Intracommunautaire" validate={required()} style={{'width':'600px'}}/>
                     
                }
            </FormDataConsumer>
            
            <TextInput source="iban" label="IBAN" validate={required()}/>
            <PasswordInput source="password" label="Mot de passe" validate={required()} style={{'width':'600px'}}/>
            <FormDataConsumer>
                {({ formData, ...rest }) =>
                    <PasswordInput {...rest} source="password_confirm" label="Mot de passe confirmé" validate={validatePassword} style={{'width':'600px'}}/>
                     
                }
            </FormDataConsumer>


            <ImageInput source="rib" label="RIB" accept="image/*" maxSize='5000000' placeholder={uploadFileText}>
                <ImageField source="url" title="Fichier" />
            </ImageInput>

            <ImageInput source="kbis" label="Extrait KBIS" accept="image/*" maxSize='5000000' placeholder={uploadFileText}>
                <ImageField source="url" title="Fichier" />
            </ImageInput>

            <ImageInput source="company_status" label="Statut de la société" accept="image/*" maxSize='5000000' placeholder={uploadFileText}>
                <ImageField source="url" title="Fichier" />
            </ImageInput>


            <ImageInput source="rcp" label="Assurance responsabilité civile" accept="image/*" maxSize='5000000' placeholder={uploadFileText}>
                <ImageField source="url" title="Fichier" />
            </ImageInput>

            {/* <Typography variant="body2">Représentant légal:</Typography> */}
            <br/>
            <Typography style={{ fontWeight: 600, fontSize:15 }}>Représentant légal</Typography>
            <TextInput source="manager_last_name" label="Nom du représentant légal" validate={required()} style={{'width':'600px'}}/>
            <TextInput source="manager_first_name" label="Prénom du représentant légal" validate={required()} style={{'width':'600px'}}/>
            <MuiPickersUtilsProvider utils={MomentUtils}>
            {/* <DateInput source="birth_day" label="Date de naissance"
            autoComplete="" options={{ format: 'dd/MM/yyyy' }} 
                 providerOptions={{ utils: DateFnsUtils, locale: frLocale }}
            /> */}

            <DateInput source="manager_birth_date" label="&nbsp;&nbsp;&nbsp;Date de naissance"
                    options={{ format: 'DD/MM/YYYY' }} 
                        providerOptions={{ utils: DateFnsUtils, locale: frLocale }}
                        validate={required()} style={{'width':'600px'}}/>


            </MuiPickersUtilsProvider>
            <TextInput source="manager_address" label="Adresse du représentant légal" validate={required()} style={{'width':'600px'}}/>
            <TextInput source="manager_postal_code" label="Code postal du représentant légal" validate={required()} style={{'width':'600px'}}/>
            <TextInput source="manager_city" label="Ville du représentant légal" validate={required()} style={{'width':'600px'}}/>

            <FormDataConsumer>
                {({ formData, ...rest }) => formData.user_type === USER_TYPE_COMPANY_AND_DRIVER &&
                <RadioButtonGroupInput source="manager_sub_type" label="Rôle dans la société" choices={[
                 { id: UB_TYPE_EXECUTIVE, name: 'Cadre' }, { id: UB_TYPE_OWNER, name: "Associé (si les parts >= 25%" },
            ]} validate={required()} />
                     
                }
            </FormDataConsumer>

            <ImageInput source="manager_id_card_recto" label="Pièce d'identité recto du représentant légal" accept="image/*" maxSize='5000000' placeholder={uploadFileText}>
                <ImageField source="url" title="Fichier" />
            </ImageInput>

            <ImageInput source="manager_id_card_verso" label="Pièce d'identité verso du représentant légal" accept="image/*" maxSize='5000000' placeholder={uploadFileText}>
                <ImageField source="url" title="Fichier" />
            </ImageInput>


            <ImageInput source="manager_address_proof_recto" label="Justificatif de domicile du représentant légal" accept="image/*" maxSize='5000000' placeholder={uploadFileText}>
                <ImageField source="url" title="Fichier" />
            </ImageInput>
          
            
        </SimpleForm>
    </Create>;
}

export default CreateDriver;

