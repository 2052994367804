import React, { Fragment } from 'react';
import { Filter, TextInput, ReferenceInput, AutocompleteInput, Pagination, SelectInput, List, Datagrid, TextField, DateField } from 'react-admin';
import BatchDeleteButton from '../../control/button/batch-delete-button';
import {OrderTypeField, OrderStatusField, FormattedDateField, FullNameDriverField, FullNameClientField, TimeSlotTextField} from '../../control/field/index'
import { activityTypes, months } from '../../util/constant';
import Typography from '@material-ui/core/Typography';
const BulkActionButtons = props => (
    <Fragment>
        {/* <BatchDeleteButton label="Supprimer" {...props} /> */}
    </Fragment>
);

// const FullNameCientField = ({ record = {}, }) => (
//     <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
//         {record.c_first_name}&nbsp;{record.c_last_name}
//     </div>
// );

// const FullNameDriverField = ({ record = {}, }) => (
//     <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
//         {record.d_first_name}&nbsp;{record.d_last_name}
//     </div>
// );


const Title = ({ record }) => {
    return <span>Produits différés</span>;
};

const ActivityTypeTextField = ({ source, record = {}, label}) => {
    for (let index = 0; index < activityTypes.length; index++) {
        const activityType = activityTypes[index];
        if(activityType.id === record.activity_type)
            return <span class="MuiTypography-root MuiTypography-body2">{activityType.name}</span>;
    }
    return <span/>;
    
}

// const FullNameField = ({ record }) => <span>{record.first_name} {record.last_name}</span>;
const ListFilter = ({ permissions, ...props })  => (
    <Filter {...props}>
        <TextInput label="Année" source="year" alwaysOn resettable/>
        {/* <TextInput label="Mois" source="month" alwaysOn resettable/> */}
        <SelectInput label="Mois" source="month" alwaysOn choices={months}
            allowEmpty emptyText="" emptyValue='-1' initialValue='-1'
        />
        {/* {permissions === 'Admin' && <SelectInput label="Type de service" source="activity_type" alwaysOn choices={activityTypes}
            allowEmpty emptyText="Tous" emptyValue='-1' initialValue='-1'/>} */}

        {/* <ReferenceInput label="Type de véhicule" source="vehicle_type_id" reference="vehicle_type" perPage={99999} alwaysOn emptyText="Tous" resettable> 
            <SelectInput optionText="name"
                
            />
 </ReferenceInput> */}
             <ReferenceInput label="Raison sociale" source="client_id" reference="client" filter={{'pay_late':'1'}} perPage={99999} alwaysOn resettable
        emptyText=""
        >
            <AutocompleteInput  optionText="temp_name"
                // optionText={choice =>
                //     `${choice.email}`
                // }
            />
        </ReferenceInput>
        {/* <ReferenceInput label="Raison sociale" source="client_id" reference="client" filter={{'pay_late':'1'}} perPage={99999} alwaysOn resettable
        emptyText=""
        >
            <AutocompleteInput  optionText="company_name"
                // optionText={choice =>
                //     `${choice.email}`
                // }
            />
        </ReferenceInput> */}
        {/* <ReferenceInput label="Email livreur" source="driver_id" reference="driver" perPage={99999} alwaysOn emptyText="Tous" resettable> 
            <AutocompleteInput optionText="email"
                // optionText={choice =>
                //     `${choice.email}`
                // }
            />
        </ReferenceInput>
        {/* <TextInput label="Adresse de départ" source="from_address" alwaysOn resettable/> */}
        {/* <TextInput label="Adresse de livraison" source="to_address" alwaysOn resettable/> */}
         
        
        {/* <TextInput label="Nom" source="last_name" alwaysOn resettable/>
        <TextInput label="Prénom" source="first_name" alwaysOn resettable/> */}
        {/* <SelectInput label="Type" source="user_type" alwaysOn choices={driverTypes} 
            optionText={<UserTypeFilterField />} allowEmpty emptyText="Tous les types" emptyValue='0' 
            initialValue='0'
            />
         */}
        {/* <TextInput label="Ville" source="create_time" resettable/>
        <TextInput label="Code postal" source="postal_code" resettable/> */}
        {/* <SelectInput label="Civilité" source="gender" choices={genders} 
            allowEmpty emptyText="Tous les types" emptyValue='0' initialValue='0'
            optionText={<GenderFilterField />}/> */}

        {/* <SelectInput label="Activation" source="activated" choices={activateds} 
            allowEmpty emptyText="Tous les deux" emptyValue='-1' initialValue='-1'
            />
        <SelectInput label="Vérrouillage" source="locked" choices={lockeds} 
            allowEmpty emptyText="Tous les deux" emptyValue='-1' initialValue='-1'
            />
        <SelectInput label="Validation" source="validated" choices={validateds} 
            allowEmpty emptyText="Tous les deux" emptyValue='-1' initialValue='-1'
            /> */}
    </Filter>
);

const Aside = ({ data, ids }) => {
    let sum = ids.map(id => data[id]).reduce((sum, row) => sum + row.total, 0);
    let total = ids.map(id => data[id]).reduce((total, row) => total + row.total_order, 0);
    sum = +sum.toFixed(2);
    if(ids.length > 0) total = total/ids.length;
    return <div style={{ width: 200, margin: '1em' }}>
        <Typography variant="title">Total TTC des produits :</Typography>
        <Typography variant="body1">
            {/* {ids.map(id => data[id]).reduce((sum, row) => sum + row.total, 0)} */}
            {sum} (€)
        </Typography>
        <br/>
        <Typography variant="title">Nombre de commandes :</Typography>
        <Typography variant="body1">
            {/* {ids.map(id => data[id]).reduce((sum, row) => sum + row.total, 0)} */}
            {total}
        </Typography>
    </div>
};

const Paging = ({ data, ids }) => {
    const sum = ids.map(id => data[id]).reduce((sum, row) => sum + row.total, 0);
    return <div style={{ width: 200, margin: '1em' }}>
        <Typography variant="title">Total TTC des produits</Typography>
        <Typography variant="body1">
            {/* {ids.map(id => data[id]).reduce((sum, row) => sum + row.total, 0)} */}
            {sum}
        </Typography>
    </div>
};

const ListOrderFinished = (props) => (
    <List title={<Title/>} {...props} aside={<Aside/>} pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]}/>}
    filters = {<ListFilter/>} sort={{ field: 'name', order: 'DESC' }}
        bulkActionButtons={<BulkActionButtons />} exporter={false}>
        <Datagrid rowClick="" >
            {/* <FormattedDateField source="create_time" format="format_date_time" label="Date/heure"/> */}
            {/* <TextField source="order_number" label="Nº de commande"/> */}
            {/* <ActivityTypeTextField label="Type de service"/> */}
            {/* <OrderTypeField source="order_type" label= "Type de commande"/>
            <FormattedDateField source="pick_time" format="format_date" label="Date de livraison"/>
            <TimeSlotTextField label="Créneau de livraison"/> */}
            {/* <ReferenceField label="Type de véhicule" source="vehicle_type_id" 
            reference="vehicle_type" link="">
                    <TextField source="name" />
                </ReferenceField> */}
            {/* <OrderActivityTypeField source="order_type" label= "Type d'activité"/> */}
            {/* <TextField source="c_last_name" label="Nom du client"/>
            <TextField source="c_first_name" label="Prénom du client"/> */}
            {/* <TextField source="from_address" label="Adresse de départ"/> */}
            <TextField source="name" label="Nom du produit"/>
            <TextField source="price" label="Montant unitaire HT(€)"/>
            <TextField source="price_total" label="Montant unitaire TTC(€)"/>
            <TextField source="quantity" label="Quantité"/>
            
            <TextField source="subtotal" label="Montant total HT(€)"/>
            {/* <TextField source="d_last_name" label="Nom du chauffeur"/>
            <TextField source="d_first_name" label="Prénom du chauffeur"/> */}
            {/* <FullNameClientField label="Client"/>
            <FullNameDriverField label="Livreur"/> */}
            <TextField source="total" label="Montant total TTC(€)"/>
            {/* <OrderStatusField source="status" label = "État"/> */}
        </Datagrid>
    </List>
);

export default ListOrderFinished;

