import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form'
import { change, submit, isSubmitting } from 'redux-form';
import {fetchEnd, fetchStart, required, showNotification, Button,
    SaveButton, SimpleForm, TextInput, crudUpdate, UPDATE,
    FileField, REDUX_FORM_NAME
} from 'react-admin';
import { useHistory } from "react-router-dom";
import { AutocompleteInput, ReferenceInput } from 'react-admin';
import { Confirm,  } from 'react-admin';
import ActionDelete from '@material-ui/icons/Delete';
import AssignIcon from '@material-ui/icons/TouchApp';
import ActionSave from '@material-ui/icons/Save';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
// import RichTextInput from 'ra-input-rich-text';

import jsonDataProvider from '../../provider/json-data-provider';
import {httpClient} from '../../util/function'
import {BACKEND_UMS_URL, BACKEND_API_KEY} from '../../util/variant'
import {VehicleStatus} from '../../util/constant'

import { log } from 'util';
const dataProvider = jsonDataProvider(BACKEND_UMS_URL, httpClient);

const DriverOptionRenderer = choice => (
    <span>
        {choice.last_name} {choice.first_name}
    </span>
);

const DriverInputText = choice => `${choice.last_name} ${choice.last_name}`;

const onSubmit = async values => {
    // window.alert(JSON.stringify(values, 0, 2))
    this.handleSubmit(values);
}


class RemoveButton extends Component {
    state = {
        isOpen: false,
    }
    
    handleClick = () => {
        this.setState({ isOpen: true });
    }

    handleDialogClose = () => {
        this.setState({ isOpen: false });
    };

    handleConfirm = () => {
        console.log("Delete button props " + JSON.stringify(this.props));
        const { basePath, crudUpdate, resource, record, redirect } = this.props;
        // crudDelete(resource, record.id, basePath);
        // crudDelete(resource, record.id, record, basePath, redirect);
        // this.setState({ isOpen: true });
        crudUpdate('order_deny', record.id, {'app_key': BACKEND_API_KEY}, basePath, 'list', true);
        this.setState({ isOpen: false });
        
        // let history = useHistory();
        // history.goBack();
    };

    // handleSubmit = values => {
    //     // const { fetchStart, fetchEnd, showNotification, record } = this.props;
    //     // console.log('Reject doc button props: ' + this.props);
    //     // Dispatch an action letting react-admin know a API call is ongoing
    //     // fetchStart();
    //     const { record, crudUpdate, basePath } = this.props;

    //     // As we want to know when the new post has been created in order to close the modal, we use the
    //     // dataProvider directly
    //     // values['id'] = record.id;
    //     // values['status'] = DataStatus.REJECTED;

    //     console.log('Reject doc button values: ' + JSON.stringify(values['id'])); 
    //     // , reason: values['reason']
    //     crudUpdate('order_deny', record.id, {}, basePath, 'show', true);

    //     // dataProvider(UPDATE, 'vehicle_status', { id: record.id, data: {id: record.id, status: VehicleStatus.LOCKED, reason: values['reason']}})
    //     //     .then(({ data }) => {
    //     //         this.setState({ showDialog: false });
    //     //     })
    //     //     .catch(error => {
    //     //         showNotification(error.message, 'error');
    //     //     })
    //     //     .finally(() => {
    //     //         // Dispatch an action letting react-admin know a API call has ended
    //     //         fetchEnd();
    //     //     });

    //     this.setState({ showDialog: false });
    // };

   

    render() {
        const { showDialog } = this.state;
        const { isSubmitting, record } = this.props;

        
        // var id = record.id;
        let submit;
        return (
            <Fragment>
                <Button label="Retirer" onClick={this.handleClick}>
                    <ActionDelete />
                </Button>
                <Confirm
                    isOpen={this.state.isOpen}
                    title="Retirer la commande"
                    content={this.props.content}
                    onConfirm={this.handleConfirm}
                    onClose={this.handleDialogClose}
                />
            </Fragment>
        );
    }
}

// const mapStateToProps = state => ({
//     isSubmitting: isSubmitting()(state)
// });

// RemoveButton.propTypes = {
//     label: PropTypes.string,
//     push: PropTypes.func,
//     record: PropTypes.object,
//     showNotification: PropTypes.func,
// };


// const mapDispatchToProps = {
//     change,
//     fetchEnd,
//     fetchStart,
//     showNotification,
//     submit
// };

// export default connect(mapStateToProps, mapDispatchToProps)(
//     LockVehicleButton
// );
// export default connect(undefined, { crudUpdate })(RemoveButton);
RemoveButton.propTypes = {
    label: PropTypes.string,
    redirect: PropTypes.string,
};

export default connect(undefined, { crudUpdate })(RemoveButton);

    // handleClick = () => {
    //     // const { push, record, showNotification } = this.props;
    //     // const updatedRecord = { ...record, is_approved: true };
    //     // fetch(`/comments/${record.id}`, { method: 'PUT', body: updatedRecord })
    //     //     .then(() => {
    //     //         showNotification('Comment approved');
    //     //         push('/comments');
    //     //     })
    //     //     .catch((e) => {
    //     //         showNotification('Error: comment not approved', 'warning')
    //     //     });
    // }

    // render() {
    //     return (
    //             <Button label={this.props.label} onClick={this.handleClick}>
    //                 <ActionEmail/>
    //             </Button>
            
    //     );
    // }
// }

// SendMailButton.propTypes = {
//     label: PropTypes.string,
//     push: PropTypes.func,
//     record: PropTypes.object,
//     showNotification: PropTypes.func,
// };

// export default connect(null, {
//     showNotification,
//     push,
// })(SendMailButton);