import React, {Fragment} from 'react';
import { List, Datagrid, TextField, ImageField, DateField, SelectInput, EditButton, Filter, TextInput, useTranslate } from 'react-admin';
import {BatchDeleteButton} from '../../control/button/index';

import {ValidateField, FormattedDateField} from '../../control/field/index'

const BulkActionButtons = props => (
    <Fragment>
        {/* <BatchDeleteButton label="Supprimer" {...props} /> */}
        <BatchDeleteButton content="Etes-vous sur de vouloir supprimer ces codes remises? Faites attention seuls les codes non-publiés peuvent être supprimés !" {...props}/>
    </Fragment>
);
const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.product.list')}</span>;
};

// const TypeTextField = ({ source, record = {}, label}) => {
//     const locationType = locationDict[record.type];
//     return <span class="MuiTypography-root MuiTypography-body2">{typeof(locationType) !== 'undefined' ? locationType.name : record.type}</span>;
// }
    
// TypeTextField.defaultProps = {
//     addLabel: true,
// };

// const unitTypes = [
//     {id: 0, name: '€'},
//     {id: 1, name: '%'}
// ];

const UnitTypeTextField = ({ source, record = {}, label}) => {
    const unitType = record.unit_type;
    return <span class="MuiTypography-root MuiTypography-body2">{unitType == '0' ? '$': '%'}</span>;
}
    
UnitTypeTextField.defaultProps = {
    addLabel: true,
};

const statusList = [
    {id: 0, name: 'Pas encore publié'},
    {id: 1, name: 'Publié'}
];

const OrderTypesField = ({ source, record = {}, label}) => {
    // const classes = useStyles();
    const orderTypes = record['order_types'];
    if(typeof(orderTypes) === 'undefined' || orderTypes.length === 0)
        return <span></span>;
    // return <div><ActivateIcon color='primary'/></div>;
    if(orderTypes.length === 2)
        return <span>Immédiate, Réservation à l'avance</span>;

    if(orderTypes.length === 1){
        if(orderTypes[0] === 0)    
            return <span>Immédiate</span>;
        else 
            if(orderTypes[0] === 1)    
                return <span>Réservation à l'avance</span>;
    }
    return <span></span>;
};
    // <div>
    //     <span class>{record[source]}</span>
    //     <img src={record[source] == TRUE ? ActivateIcon : NotActivateIcon} 
    //         width='20' height='20'
    //         alt={''}/>
    // </div>;

OrderTypesField.defaultProps = {
    addLabel: true,
};

const ListFilter = ({ permissions, ...props })  => (
    <Filter {...props}>
        <TextInput label="Référence du produit" source="ref" alwaysOn resettable/>
        <TextInput label="Titre du produit" source="name" alwaysOn resettable/>
        {/* <SelectInput label="Titre du produit" source="name" alwaysOn choices={statusList} 
        allowEmpty emptyText="Tous" emptyValue='-1' initialValue='-1'/> */}
        {/* <DateInput source="end" parse={v => v && v.toISOString()} providerOptions={{ utils: MomentUtils }} /> */}
    </Filter>
);
const rowClick = (id, basePath, record) => parseInt(record.published) === 1 ? 'show' : 'edit';
const ListProduct = ({permissions, ...props}) => (
    <List title={<Title/>} {...props} sort={{ field: 'name', order: 'DESC' }}
        filters = {<ListFilter permissions={permissions} />} 
        bulkActionButtons={<BulkActionButtons />} exporter={false}>
        <Datagrid rowClick='edit'>
            <TextField source="ref" label = "Référence du produit"/>
            <TextField source="name" label = "Titre du produit"/>
            <TextField source="description" label = "Description"/>
            {/* <UnitTypeTextField source="unit_type" label="Unité"/> */}
            <TextField source="price" label = "Prix HT / unité (€)"/>
            <TextField source="vat" label = "Taux de TVA (%)"/>
            <OrderTypesField label="Types de commande"/>
            <ImageField source="photo_url" label="Photo du produit" size='100'/>
            {/* <FormattedDateField source="create_time" label="Date de création"  locales="fr-FR" format="format_date_time" /> */}
            <ValidateField source="locked" label="Verrouillé ?"/>
            {/* <EditButton/> */}
        </Datagrid>
    </List>
);

export default ListProduct;

