import React, {Fragment} from 'react';
import { usePermissions, Pagination,ReferenceInput, AutocompleteInput, ReferenceField, List, Datagrid, TextField, DateField, EditButton, Filter, TextInput, SelectInput, useTranslate } from 'react-admin';

import {countries} from '../../util/constant';
// import BatchDeleteButton from '../../control/button/batch-delete-button';


import {GENDER_MALE, GENDER_FEMALE, USER_TYPE_AUTO_ENTERPRENER, USER_TYPE_COMPANY_AND_DRIVER, USER_TYPE_DRIVER_COMPANY, ACTIVITY_TYPE_VTC, ACTIVITY_TYPE_ATR} from '../../util/constant';

import {FormattedDateField, DriverStatusField, VerifyField, AvatarEmailField, FullNameField, GenderField, 
    CountryTextField, DriverCompanyTypeFilterField, ActivityTypeFilterField, ActivityTypeField,
    GenderFilterField, LockField, ValidateField, AvatarField} from '../../control/field/index'
import {NoticeSendButton} from '../../control/button/index'


const BulkActionButtons = props => (
    <Fragment>
        {/* <BatchDeleteButton label="Supprimer" {...props} /> */}
        {/* <NoticeSendButton {...props} toClient={false}/> */}
    </Fragment>
);
const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.driver.list')}</span>;
};

// var driverTypes = [];
// driverTypes.push({id: USER_TYPE_AUTO_ENTERPRENER, name: "Personne physique"});
// driverTypes.push({id: USER_TYPE_COMPANY_AND_DRIVER, name: "Société"});

// var activityTypes = [];
// activityTypes.push({id: ACTIVITY_TYPE_VTC, name: "VTC"});
// activityTypes.push({id: ACTIVITY_TYPE_ATR, name: "Transport léger des marchandises"});

// const UserTypeField = ({ record = {}, label}) => {
//     return <div>
//         <span class>{record.user_type == USER_TYPE_COMPANY_AND_DRIVER? 'Autre' : 'Auto-entrepreneur'}</span>
//     </div>;
// }

// const UserTypeFilterField = ({ record }) => {
//     return <span>{record.id == USER_TYPE_AUTO_ENTERPRENER ? 'Auto-entrepreneur' : 'Autre'}</span>;
// };

// const FormattedDateField = ({ record = {}, source, label, locales, className}) => {
//     // const classes = useStyles();
//     if(typeof(record) === 'undefined' || 
//         typeof(record[source]) === 'undefined' 
//         || record[source] === "")
//         return <span></span>
    
//     moment.locale('fr');
//     const value = moment(record[source], "YYYY-MM-DD HH:mm:ss");
//     return <spam>{value.format('DD/MM/YYYY')}</spam>
// }

// FormattedDateField.defaultProps = {
//     addLabel: true,
// };

var genders = [];
genders.push({id: GENDER_MALE, name: 'Monsieur'});
genders.push({id: GENDER_FEMALE, name: "Madame"});

var lockeds = [];
lockeds.push({id: 0, name: 'Non-vérrouillé'});
lockeds.push({id: 1, name: "Vérrouillé"});

var validateds = [];
validateds.push({id: 0, name: 'Non-validé'});
validateds.push({id: 1, name: "Validé"});

var activateds = [];
activateds.push({id: 0, name: 'Non-activé'});
activateds.push({id: 1, name: "Activé"});



const ListFilter = ({ permissions, ...props })  => {
    return (
    <Filter {...props}>
        <TextInput label="Email" source="email" alwaysOn resettable/>
        <TextInput label="Mobile" source="mobile" alwaysOn resettable/>
        <TextInput label="Nom" source="last_name" alwaysOn resettable/>
        <TextInput label="Prénom" source="first_name" alwaysOn resettable/>
        {/* <ReferenceInput label="Wilaya" source="region_id" emptyText="Tous" 
            sort={{field: 'name', order: 'ASC'}}
            reference="region" perPage={99999} resettable>
            <AutocompleteInput optionText="name"
                
            />
        </ReferenceInput> */}
        {/* <SelectInput label="Type" source="user_type" alwaysOn choices={driverTypes} 
            optionText={<DriverCompanyTypeFilterField />} allowEmpty emptyText="Tous les types" emptyValue='0' 
            initialValue='0'
            /> */}

{/* <SelectInput label="Activité" source="activity_type" alwaysOn choices={activityTypes} 
            optionText={<ActivityTypeFilterField />} allowEmpty emptyText="Tous les types" emptyValue='0' 
            initialValue='0'
            /> */}
        
        {/* <TextInput label="Adresse" source="address" resettable/> */}
        {/* <TextInput label="Code postal" source="postal_code" resettable/> */}
        {/* <TextInput label="Identifiant unique" source="user_code" resettable/> */}
        {/* <SelectInput label="Identifiant unique" source="user_code" choices={genders} 
            allowEmpty emptyText="Tous les types" emptyValue='0' initialValue='0'
            optionText={<GenderFilterField />}/> */}

        {/* <SelectInput label="Activation" source="activated" choices={activateds} 
            allowEmpty emptyText="Tous les deux" emptyValue='-1' initialValue='-1'
            /> */}
        <SelectInput label="Vérrouillage" source="locked" choices={lockeds} 
            allowEmpty emptyText="Tous les deux" emptyValue='-1' initialValue='-1'
            />
        {/* <SelectInput label="Complétion" source="validated" choices={validateds} 
            allowEmpty emptyText="Tous les deux" emptyValue='-1' initialValue='-1'
            /> */}
    </Filter>);
}

const ListPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50]} {...props} />;



const ListDriver = ({permissions, ...props}) => {
    const translate = useTranslate();
    return(
    <List pagination={<ListPagination/>} title={<Title/>} {...props} perPage={50}
        filters = {<ListFilter permissions={permissions} />}
        bulkActionButtons={<BulkActionButtons />} exporter={false}>
        <Datagrid rowClick="show">
            {/* <AvatarField source="photo_url" label="" /> */}
            {/* <TextField source="user_code" label="Identifiant unique"/> */}
            {/* <GenderField source="gender" label={translate('civility')}/> */}
            <TextField source="last_name" label="Nom"/>
            <TextField source="first_name" label="Prénom"/>
            <TextField source="mobile" label="Mobile"/>
            {/* <VerifyField source="activated" label="Mobile vérifié?"/> */}
            <TextField source="email" label="Email"/>
            {/* <TextField source="balance" label="Wallet(€)"/> */}
            {/* <DriverCompanyTypeField source="user_type" label="Type du chauffeur"/> */}
            {/* <ActivityTypeField source="activity_type" label="Type d'activité"/> */}
            {/* <TextField source="company_name" label="Nom de société"/> */}
            {/* <ReferenceField label="Nom de société" source="company_id" reference="driver_company" link="show">
                    <TextField source="company_name" />
                </ReferenceField> */}
                {/* <TextField source="company_name" label="Nom de société"/>
                <TextField source="company_address" label="Adresse du siège social"/> */}
            {/* <TextField source="postal_code" label="Code postal"/> */}
            <FormattedDateField source="birth_date" label="Date de naissance"  locales="fr-FR"/>
            
            {/* <TextField source="address" label="Adresse ou quartier"/>
            <TextField source="city" label="Ville"/> */}
            {/* <TextField source="country_name" label="Pays"/> */}
             {/* <CountryTextField source="country" label="Pays"/> */}

            {/* <ReferenceField label="Wilaya" source="region_id" reference="region" link="show">
                    <TextField source="name" />
                </ReferenceField> */}
            <DriverStatusField source="online_status" label="Statut"/>
            <FormattedDateField source="create_time" label="Date d'inscription"  locales="fr-FR"/>
            {/* <ValidateField source="activated" label='Activé'/> */}
            {/* <ValidateField source="validated" label='Validé'/> */}
            <ValidateField source="locked" label='Verrouillé'/>
            {/* <DateField source="create_time" label="Date de création"  locales="fr-FR"/> */}
            {/* <TextField source="creator.email" label="Créateur"/> */}
            {/* <DateField source="create_time" label="Date de création"  locales="fr-FR"/> */}
            {/* <DateField source="update_time" label="Date de modification"  locales="fr-FR"/> */}
            {/* <EditButton/> */}
        </Datagrid>
    </List>);
};

export default ListDriver;

