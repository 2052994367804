
import React from 'react';
import { number, TextField, BooleanInput, FormDataConsumer, PasswordInput, ImageField, email, TabbedForm, RadioButtonGroupInput, SelectInput, TextInput, Edit, SimpleForm, required, useTranslate } from 'react-admin';
import CardActions from '@material-ui/core/CardActions';
import { ListButton, Toolbar, SaveButton, DeleteButton } from 'react-admin';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';

import Typography from '@material-ui/core/Typography';

import { DateInput } from 'react-admin-date-inputs2';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
// import MomentUtils from 'material-ui-pickers/utils/moment-utils';
import frLocale from "date-fns/locale/fr";
import {countries} from '../../util/constant';
// import {locationTypes} from '../../util/variant';
import MomentUtils from '@date-io/moment';
import { ValidityDateField } from '../../control/field';
DateFnsUtils.prototype.getStartOfMonth = DateFnsUtils.prototype.startOfMonth

const validateConfirmPassword = (value, allValues) => {
    if (value !== allValues.password) {
        return 'Mots de passe ne sont pas identiques';
    }
    return [];
};

// const validateMobileNumber = (value, allValues) => {
//     if (!value.startsWith('33') || value.length !== 12) {
//         return "Doit être 12 chiffres sous forme '330xxxxxxxxx'";
//     }
//     return [];
// };
const validateMobileNumber = (value, allValues) => {
    if (!value.startsWith('224') || value.length !== 12) {
        return "Doit être sous forme '224xxxxxxxxx'";
    }
    // if (value.length !== 10) {
    //     return "Doit être 10 chiffres";
    // }
    return [];
};

const validatePassword = [required(), validateConfirmPassword];
const validateEmail = [required(), email()];
const validateMobile = [required(), validateMobileNumber];

// import {GENDER_MALE, GENDER_FEMALE, USER_TYPE_AUTO_ENTERPRENER, USER_TYPE_COMPANY_AND_DRIVER} from '../../util/constant';

const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

const CustomToolbarNoStyle = ({permissions, ...props}) => (
    <Toolbar {...props}>
        <SaveButton />
        {/* <DeleteButton label="ra.action.delete" redirect='list' {...props}/> */}
    </Toolbar>
);

const CustomToolbar = withStyles(toolbarStyles)(CustomToolbarNoStyle);

const cardActionStyle = {
    zIndex: 2,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    padding: 0,
};

const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
    colorLabel: {
        // paddingLeft: 50,
        color: '#F4004E',
    },

    noColorLabel: {
        paddingLeft: 50,  
    },

    colorValue: {
        color: '#F4004E',
        paddingLeft:5
    },

    supplementText: {
        color: '#F4004E',
    },

    noHeader:{
        display:'none',
        // height:'1px'
    }
    
});

const styles = {
    flex: { display: 'flex', },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1, marginRight: '1em' },
    rightCol: { flex: 1, marginLeft: '1em' },
    singleCol: { marginTop: '2em', marginBottom: '2em' },
    map:{ flex: 1, marginTop: '1em', marginBottom: '1em' },
    button:{ flex: 1, marginTop: '1em'},
};

// const tzOffset = new Date().getTimezoneOffset() / 60
// const dateParser = v => {
//   const regexp = /(\d{4})-(\d{2})-(\d{2})(T|\s)(\d{2}):(\d{2})/
//   let match = regexp.exec(v)
//   if (match === null) return "No match"

//   let year = match[1]
//   let month = match[2]
//   let day = match[3]
//   let hour = match[5]
//   let min = match[6]

//   if (tzOffset < 0) {
//     const date = new Date(v)
//     match = regexp.exec(date.toISOString())
//     if (!match) return null
//     year = match[1]
//     month = match[2]
//     day = match[3]
//     hour = match[4]
//     min = match[5]
//   }
//   const date = [year, month, day].join('-')
//   const time = [hour, min].join(':')
//   const result = date + 'T' + time + 'Z'
//   return result
// }

const EditActions = ({ basePath, data, resource }) => (
    <CardActions style={cardActionStyle}>
        {/* <ListButton basePath={basePath}/> */}
    </CardActions>
);
const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.driver.edit')}: {record ? `${record.email}` : ''}</span>;
};

// var genders = [];
// genders.push({id: GENDER_MALE, name: 'Monsieur'});
// genders.push({id: GENDER_FEMALE, name: "Madame"});

// var driverTypes = [];
// driverTypes.push({id: USER_TYPE_AUTO_ENTERPRENER, name: "Auto-entrepreneur"});
// driverTypes.push({id: USER_TYPE_COMPANY_AND_DRIVER, name: "Autre"});

const validatePostalCodeRange = (value, allValues) => {
  if (value < 1000 || value > 99000) {
      return 'Doit être en [01000, 99000]';
  }
  return [];
};

const validatePostalCode = [required(), number(), validatePostalCodeRange];

const EditDriver = ({permissions, ...props })  => {
    const classes = useStyles();
    return(
        <Edit title={<Title />} {...props} actions={<EditActions/>}>
        <SimpleForm redirect="show" >
        <Typography style={{ fontWeight: 600, fontSize:15 }}>Profil</Typography>
        <TextField source="email" label="Email" validate={required()}  editable = {false}/>
        <TextField source="last_name" label="Nom" validate={required()}  editable = {false}/>
        <TextField source="first_name" label="Prénom" validate={required()}  editable = {false}/>
        <TextField source="mobile" label="Mobile" validate={required()}  editable = {false}/>
        <ValidityDateField source="birth_date" label="Date de naissance" validate={required()}  editable = {false}/>

        <Typography style={{ fontWeight: 600, fontSize:15 }}>Paramètres</Typography>
                <BooleanInput label="Voir commandes du jour" source="today_view"/>
                <BooleanInput label="Voir toutes les commandes" source="all_view"/>
                <BooleanInput label="Recevoir les commandes immédiate" source="immediate_receive"/>
                <BooleanInput label="Recevoir les commandes en réservation" source="reservation_receive"/>
                <BooleanInput label="Recevoir les commandes payées en carte" source="in_card_receive"/>
                <BooleanInput label="Recevoir les commandes payées en espèces" source="in_cash_receive"/>
        {/* <TextInput source="last_name" label="Nom" validate={required()}/>
        <TextInput source="first_name" label="Prénom" validate={required()}/>
        
        <TextInput source="mobile" label="Mobile" validate={required()}/> */}
        {/* <SelectInput source="type" label="Type" validate={required()} choices={locationTypes}/> */}
        {/* <MuiPickersUtilsProvider utils={MomentUtils}> */}
        {/* <DateInput source="birth_day" label="Date de naissance"
        autoComplete="" options={{ format: 'dd/MM/yyyy' }} 
             providerOptions={{ utils: DateFnsUtils, locale: frLocale }}
        /> */}

        {/* <DateInput source="birth_date" label="&nbsp;&nbsp;&nbsp;Date de naissance"
        
                options={{ format: 'DD/MM/YYYY' }} 
                    providerOptions={{ utils: MomentUtils, locale: frLocale }}
                    validate={required()}/>

        </MuiPickersUtilsProvider> */}
        {/* <DateInput source="birth_day" label="Date de naissance"
             providerOptions={{ utils: DateFnsUtils, locale: frLocale }}
        /> */}
        {/* <TextInput source="address" label="Adresse ou quartier" validate={required()}/>
        <TextInput source="city" label="Ville" validate={required()}/> */}
        {/* <TextInput source="country" label="Pays" validate={required()}/> */}
        {/* <SelectInput label="Pays" source="country" choices={countries} 
            optionText="name" optionValue="id"
        /> */}

        {/* <PasswordInput source="password" label="Mot de passe" validate={required()}/>
        <FormDataConsumer>
            {({ formData, ...rest }) =>
                <PasswordInput {...rest} source="password_confirm" label="Mot de passe confirmé" validate={validatePassword}/>
                 
            }
        </FormDataConsumer> */}

        {/* <RadioButtonGroupInput source="payment_type_id" label="Mode de paiement" options={{row: true}}
        choices={[{ id: '0', name: 'Compte bancaire' }, { id: '1', name: 'Espèces' }, { id: '2', name: 'Mobile banking' },
        ]} initialValue='0'/>  

        <FormDataConsumer subscription={{ values: true }}>
             {({ formData, ...rest }) => 
                (
                formData.payment_type_id === '0' ||
                formData.payment_type_id === '2' 
                ) &&
                <TextInput label="Ref. mode de paiement" source="payment_type_value"/>
                    
             }
         </FormDataConsumer>
        
        
        
        
        <FileInput source="photo" label="Photo" multiple={false} accept="image/*">
            <ImageField source="src" title="title" label="Nom du fichier" target="_blank"/>
        </FileInput>      

        <FileInput source="driving_license" label="Permis de conduire" multiple={false} accept="image/*">
                <ImageField source="src" title="title" label="Nom du fichier" target="_blank"/>
            </FileInput>      

            <FileInput source="id_card" label="Pièce d'identité" multiple={false} accept="image/*">
                <ImageField source="src" title="title" label="Nom du fichier" target="_blank"/>
            </FileInput>     */}
</SimpleForm>
          
    </Edit>
);
};

export default EditDriver;