import React, {Fragment} from 'react';
import { List, Datagrid, TextField, Pagination, downloadCSV, DateInput, DateField, SelectInput, EditButton, Filter, TextInput, useTranslate } from 'react-admin';
import BatchDeleteButton from '../../control/button/batch-delete-button';
import {locationDict, locationTypes} from '../../util/variant'
import jsonExport from 'jsonexport/dist'
import frLocale from "date-fns/locale/fr";

const BulkActionButtons = props => (
    <Fragment>
        {/* <BatchDeleteButton label="Supprimer" {...props} /> */}
    </Fragment>
);
const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.gain.list')}</span>;
};

const TypeTextField = ({ source, record = {}, label}) => {
    const locationType = locationDict[record.type];
    return <span class="MuiTypography-root MuiTypography-body2">{typeof(locationType) !== 'undefined' ? locationType.name : record.type}</span>;
}
    
TypeTextField.defaultProps = {
    addLabel: true,
};

const exporter = orders => {
    const dataForExport = orders.map(order => {
        const { id, client_id, driver_id, status, c_email, c_first_name, c_last_name, c_mobile, d_email, d_first_name, d_last_name, d_mobile, ...dataForExport } = order; // omit backlinks and author
        // postForExport.author_name = post.author.name; // add a field
        return dataForExport;
    });
    jsonExport(dataForExport, {
        headers: ['company_name', 'last_name', 'first_name', 'iban', 'value'], // order fields in the export
        rename: ['NOM DE SOCIETE', 'NOM', 'PRENOM', 'IBAN', 'GAIN (EURO)'],
        verticalOutput: false,
        booleanTrueString: 'Oui',
        booleanFalseString: 'Non',
        // rowDelimiter: "\t",
        // forceDelimiter: true,
    }, (err, csv) => {
        downloadCSV(csv, 'gains'); // download as 'posts.csv` file
    });
};

const ListFilter = ({ permissions, ...props })  => (
    <Filter {...props}>
        <TextInput label="Nom de société" source="company_name" alwaysOn resettable/>
        <TextInput label="Nom" source="last_name" alwaysOn resettable/>
        <TextInput label="Prénom" source="first_name" alwaysOn resettable/>
        <DateInput label="Date de début" source="from_date" alwaysOn resettable options={{ format: 'DD/MM/YYYY', locale: frLocale }}/>
        <DateInput label="Date de fin" source="to_date" alwaysOn resettable options={{ format: 'DD/MM/YYYY', locale: frLocale  }}/>
        
    </Filter>
);

const ListPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50]} {...props} />;
const ListGain = ({permissions, ...props}) => (
    <List title={<Title/>} {...props} pagination={<ListPagination/>} perPage={25}
        filters = {<ListFilter/>} empty={false} 
        bulkActionButtons={<BulkActionButtons />} exporter={exporter}>
        <Datagrid rowClick="">
            <TextField source="company_name" label="Nom de société"/>
            <TextField source="last_name" label="Nom"/>
            <TextField source="first_name" label="Prénom"/>
            <TextField source="iban" label="IBAN"/>
            <TextField source="value" label="Gain à payer ($)"/>
            
        </Datagrid>
    </List>
);

export default ListGain;

