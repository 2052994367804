import React, { Fragment } from 'react';
import { ReferenceField, Filter, ReferenceArrayInput, DateInput, SelectArrayInput, ChipField, TextInput, ReferenceInput, AutocompleteInput, Pagination, SelectInput, List, Datagrid, TextField, DateField } from 'react-admin';
import BatchDeleteButton from '../../control/button/batch-delete-button';
import { OrderTypeField, OrderStatusField, FormattedDateField, TimeSlotTextField, ClientTypeField } from '../../control/field/index'
import { activityTypes, clientTypes, months } from '../../util/constant';
import Typography from '@material-ui/core/Typography';
import frLocale from "date-fns/locale/fr";

const BulkActionButtons = props => (
    <Fragment>
        {/* <BatchDeleteButton label="Supprimer" {...props} /> */}
    </Fragment>
);

// const FullNameCientField = ({ record = {}, }) => (
//     <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
//         {record.c_first_name}&nbsp;{record.c_last_name}
//     </div>
// );

// const FullNameDriverField = ({ record = {}, }) => (
//     <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
//         {record.d_first_name}&nbsp;{record.d_last_name}
//     </div>
// );


const Title = ({ record }) => {
    return <span>Chiffres</span>;
};

const ActivityTypeTextField = ({ source, record = {}, label }) => {
    for (let index = 0; index < activityTypes.length; index++) {
        const activityType = activityTypes[index];
        if (activityType.id === record.activity_type)
            return <span class="MuiTypography-root MuiTypography-body2">{activityType.name}</span>;
    }
    return <span />;

}
const Aside = ({ data, ids }) => {
    // let sum = ids.map(id => data[id]).reduce((sum, row) => sum + row.total, 0);
    console.log("Aside data: " + JSON.stringify(data));
    console.log("Aside ids: " + JSON.stringify(ids));
    let total = ids.map(id => data[id]).reduce((total, row) => total + parseFloat(row.total), 0);
    total = total.toFixed(2);
    // sum = +sum.toFixed(2);
    // if(ids.length > 0) total = total/ids.length;
    return <div style={{ width: 200, margin: '1em' }}>
        <Typography variant="title">CA Total :</Typography>
        <Typography variant="body1">
            {/* {ids.map(id => data[id]).reduce((sum, row) => sum + row.total, 0)} */}
            {total} (€)
        </Typography>

    </div>
};
// const FullNameField = ({ record }) => <span>{record.first_name} {record.last_name}</span>;
const ListFilter = ({ permissions, ...props }) => (
    <Filter {...props}>
        <DateInput label="Date de début" source="from_date" alwaysOn resettable options={{ format: 'DD/MM/YYYY', locale: frLocale }} />
        <DateInput label="Date de fin" source="to_date" alwaysOn resettable options={{ format: 'DD/MM/YYYY', locale: frLocale }} />
        <ReferenceArrayInput label="Produits" alwaysOn reference="product" source="product_id">
            <SelectArrayInput>
                <ChipField source="name" />
            </SelectArrayInput>
        </ReferenceArrayInput>

        <ReferenceInput label="Type de paiement" source="payment_type_id" reference="payment_type" perPage={99999} alwaysOn initialValue="1" emptyText="Tous" resettable>
            <SelectInput optionText="name"

            />
        </ReferenceInput>
        {/* <SelectInput label="Type de client" source="client_type" alwaysOn choices={clientTypes}
            // optionText={<UserTypeFilterField />} 
            allowEmpty emptyText="Tous les types" emptyValue='0'
            initialValue='0'
        /> */}
        <ReferenceInput label="Livreur" source="driver_id" reference="driver_all" perPage={99999} alwaysOn emptyText="Tous" resettable>
            <AutocompleteInput optionText="email"
            // optionText={choice =>
            //     `${choice.email}`
            // }
            />
        </ReferenceInput>

        <ReferenceInput label="Client" source="client_id" reference="client_all" perPage={99999} alwaysOn emptyText="Tous" resettable>
            <AutocompleteInput optionText="email"
            // optionText={choice =>
            //     `${choice.email}`
            // }
            />
        </ReferenceInput>

        {/* <TextInput label="Année" source="year" alwaysOn resettable/> */}
        {/* <TextInput label="Mois" source="month" alwaysOn resettable/> */}
        {/* <SelectInput label="Mois" source="month" alwaysOn choices={months}
            allowEmpty emptyText="" emptyValue='-1' initialValue='-1'
        /> */}
        {/* {permissions === 'Admin' && <SelectInput label="Type de service" source="activity_type" alwaysOn choices={activityTypes}
            allowEmpty emptyText="Tous" emptyValue='-1' initialValue='-1'/>} */}

        {/* <ReferenceInput label="Type de véhicule" source="vehicle_type_id" reference="vehicle_type" perPage={99999} alwaysOn emptyText="Tous" resettable> 
            <SelectInput optionText="name"
                
            />
 </ReferenceInput> */}
        {/* <ReferenceInput label="Raison sociale" source="client_id" reference="client" filter={{'pay_late':'1'}} perPage={99999} alwaysOn resettable
        emptyText=""
        >
            <AutocompleteInput  optionText="temp_name"
                // optionText={choice =>
                //     `${choice.email}`
                // }
            />
        </ReferenceInput> */}
        {/* <ReferenceInput label="Raison sociale" source="client_id" reference="client" filter={{'pay_late':'1'}} perPage={99999} alwaysOn resettable
        emptyText=""
        >
            <AutocompleteInput  optionText="company_name"
                // optionText={choice =>
                //     `${choice.email}`
                // }
            />
        </ReferenceInput> */}
        {/* <ReferenceInput label="Email livreur" source="driver_id" reference="driver" perPage={99999} alwaysOn emptyText="Tous" resettable> 
            <AutocompleteInput optionText="email"
                // optionText={choice =>
                //     `${choice.email}`
                // }
            />
        </ReferenceInput>
        {/* <TextInput label="Adresse de départ" source="from_address" alwaysOn resettable/> */}
        {/* <TextInput label="Adresse de livraison" source="to_address" alwaysOn resettable/> */}


        {/* <TextInput label="Nom" source="last_name" alwaysOn resettable/>
        <TextInput label="Prénom" source="first_name" alwaysOn resettable/> */}
        {/* <SelectInput label="Type" source="user_type" alwaysOn choices={driverTypes} 
            optionText={<UserTypeFilterField />} allowEmpty emptyText="Tous les types" emptyValue='0' 
            initialValue='0'
            />
         */}
        {/* <TextInput label="Ville" source="create_time" resettable/>
        <TextInput label="Code postal" source="postal_code" resettable/> */}
        {/* <SelectInput label="Civilité" source="gender" choices={genders} 
            allowEmpty emptyText="Tous les types" emptyValue='0' initialValue='0'
            optionText={<GenderFilterField />}/> */}

        {/* <SelectInput label="Activation" source="activated" choices={activateds} 
            allowEmpty emptyText="Tous les deux" emptyValue='-1' initialValue='-1'
            />
        <SelectInput label="Vérrouillage" source="locked" choices={lockeds} 
            allowEmpty emptyText="Tous les deux" emptyValue='-1' initialValue='-1'
            />
        <SelectInput label="Validation" source="validated" choices={validateds} 
            allowEmpty emptyText="Tous les deux" emptyValue='-1' initialValue='-1'
            /> */}
    </Filter>
);

// const Aside = ({ data, ids }) => {
//     let sum = ids.map(id => data[id]).reduce((sum, row) => sum + row.total, 0);
//     let total = ids.map(id => data[id]).reduce((total, row) => total + row.total_order, 0);
//     sum = +sum.toFixed(2);
//     if(ids.length > 0) total = total/ids.length;
//     return <div style={{ width: 200, margin: '1em' }}>
//         <Typography variant="title">Total TTC des produits :</Typography>
//         <Typography variant="body1">
//             {/* {ids.map(id => data[id]).reduce((sum, row) => sum + row.total, 0)} */}
//             {sum} (€)
//         </Typography>
//         <br/>
//         <Typography variant="title">Nombre de commandes :</Typography>
//         <Typography variant="body1">
//             {/* {ids.map(id => data[id]).reduce((sum, row) => sum + row.total, 0)} */}
//             {total}
//         </Typography>
//     </div>
// };
const FullNameDriverField = ({ record = {}, }) => {
    if (record === null || record === 'undefined') return <div />;
    var driver = record.driver;
    if (driver === null || driver === 'undefined') return <div />;
    driver = JSON.parse(driver);
    return <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
        {driver.first_name}&nbsp;{driver.last_name}({driver.email})
    </div>;
};
FullNameDriverField.defaultProps = {
    addLabel: true,
};

const FullNameClientField = ({ record = {}, }) => {
    if (record === null || record === 'undefined') return <div />;
    var client = record.client;
    if (client === null || client === 'undefined') return <div />;
    client = JSON.parse(client);
    return <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
        {client.first_name}&nbsp;{client.last_name}({client.email})
    </div>;
};
FullNameClientField.defaultProps = {
    addLabel: true,
};


const ListOrderFinished = (props) => (
    <List title={<Title />} {...props} aside={<Aside />} pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
        filters={<ListFilter />} sort={{ field: 'name', order: 'DESC' }}
        bulkActionButtons={<BulkActionButtons />} exporter={false}>
        <Datagrid rowClick="" >
            <TextField source="total" label="CA Total" />
            <ReferenceField label="Type de paiement" source="payment_type_id"
                reference="payment_type" link="">
                <TextField source="name" />
            </ReferenceField>
            <ClientTypeField label="Type de client" />
            {/* <ReferenceField label="Livreur" source="driver_id"
                reference="driver" link="">
                <TextField source="name" />
            </ReferenceField> */}
            <FullNameDriverField label="Livreur" />
            <FullNameClientField label="Client" />
            <ReferenceField label="Produit" source="product_id"
                reference="product" link="">
                <TextField source="name" />
            </ReferenceField>


        </Datagrid>
    </List>
);

export default ListOrderFinished;

