import React, {Fragment} from 'react';
import { List, Datagrid, TextField, ReferenceField, ReferenceArrayField, SingleFieldList, ChipField, SelectInput, EditButton, Filter, TextInput, useTranslate } from 'react-admin';
import {BatchDeleteButton} from '../../control/button/index';

import {ValidateField, FormattedDateField} from '../../control/field/index'

const BulkActionButtons = props => (
    <Fragment>
        {/* <BatchDeleteButton label="Supprimer" {...props} /> */}
        <BatchDeleteButton content="Etes-vous sur de vouloir supprimer ces codes remises? Faites attention seuls les codes non-publiés peuvent être supprimés !" {...props}/>
    </Fragment>
);
const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.promo_code.list')}</span>;
};

// const TypeTextField = ({ source, record = {}, label}) => {
//     const locationType = locationDict[record.type];
//     return <span class="MuiTypography-root MuiTypography-body2">{typeof(locationType) !== 'undefined' ? locationType.name : record.type}</span>;
// }
    
// TypeTextField.defaultProps = {
//     addLabel: true,
// };

// const unitTypes = [
//     {id: 0, name: '€'},
//     {id: 1, name: '%'}
// ];

const UnitTypeTextField = ({ source, record = {}, label}) => {
    const unitType = record.unit_type;
    return <span class="MuiTypography-root MuiTypography-body2">{unitType == '0' ? '€': '%'}</span>;
}
    
UnitTypeTextField.defaultProps = {
    addLabel: true,
};

const statusList = [
    {id: 0, name: 'Pas encore publié'},
    {id: 1, name: 'Publié'}
];


const ListFilter = ({ permissions, ...props })  => (
    <Filter {...props}>
        <TextInput label="Code" source="code" alwaysOn resettable/>
        <SelectInput label="État" source="published" alwaysOn choices={statusList} 
        allowEmpty emptyText="Tous" emptyValue='-1' initialValue='-1'/>
        {/* <DateInput source="end" parse={v => v && v.toISOString()} providerOptions={{ utils: MomentUtils }} /> */}
    </Filter>
);
const rowClick = (id, basePath, record) => parseInt(record.published) === 1 ? 'show' : 'edit';
const ListPromoCode = ({permissions, ...props}) => (
    <List title={<Title/>} {...props} sort={{ field: 'create_time', order: 'DESC' }}
        filters = {<ListFilter permissions={permissions} />} 
        bulkActionButtons={<BulkActionButtons />} exporter={false}>
        <Datagrid rowClick={rowClick}>
            <TextField source="code" label = "Code"/>
            <TextField source="name" label = "Nom"/>
            <TextField source="value" label = "Valeur"/>
            <UnitTypeTextField source="unit_type" label="Unité"/>
            <TextField source="max_count" label = "Nombre maximum d'utlisation par client"/>
            {/* <ReferenceField label="Produit" source="product_id" reference="product" link="" perPage={99999}>
                    <TextField source="name" />
                </ReferenceField> */}
            <ReferenceArrayField label="Produits" reference="product" source="product_ids">
                        <SingleFieldList>
                        <ChipField source="name"/>
                    </SingleFieldList>
                    </ReferenceArrayField>
            <FormattedDateField source="begin_time" label="Date de début de validité"  locales="fr-FR" format="format_date_time" />
            <FormattedDateField source="end_time" label="Date de fin de validité"  locales="fr-FR" format="format_date_time" />
            
            {/* <FormattedDateField source="create_time" label="Date de création"  locales="fr-FR" format="format_date_time" /> */}
            <ValidateField source="published" label="Publié"/>
            {/* <EditButton/> */}
        </Datagrid>
    </List>
);

export default ListPromoCode;

