
import React, {Fragment} from 'react';
import { SimpleShowLayout,TextField, ReferenceManyField, Datagrid, Show, useTranslate, Labeled } from 'react-admin';
import { EditButton } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
// const Title = ({ record }) => {
//     return <span>Mon profil</span>;
// };
import {OrderTypeField, TimeSlotTextField, OrderStatusField, FullNameClientField, FullNameDriverField, FormattedDateField} from '../../control/field/index'
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import {activityTypes, ACTIVITY_TYPE_DELIVERY, ACTIVITY_TYPE_TOURISM, ACTIVITY_TYPE_UTILITY} from '../../util/constant'

const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};

const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
    colorPrimary: {
        color:'#F4004E',
    },
});

const ShowActions = ({ basePath, data, resource }) => (
    <CardActions style={cardActionStyle}>
        {/* <EditButton basePath='/my-profile' record={data} /> */}
        {/* Add your custom actions */}
        {/* <Button color="primary" onClick={changePassword}>CHANGER LE MOT DE PASSE</Button> */}
        {/* <Link to="/change-password">Changer le mot de passe</Link> */}
    </CardActions>
);

const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.order_finished.show')}:#{record ? `${record.order_number}` : ''}</span>;
    
};

// const TypeTextField = ({ source, record = {}, label}) => {
//     const locationType = locationDict[record.type];
//     return <span class="MuiTypography-root MuiTypography-body2">{typeof(locationType) !== 'undefined' ? locationType.name : record.type}</span>;
// }
    
// TypeTextField.defaultProps = {
//     addLabel: true,
// };

const ActivityTypeTextField = ({ source, record = {}, label}) => {
    for (let index = 0; index < activityTypes.length; index++) {
        const activityType = activityTypes[index];
        if(activityType.id === record.activity_type)
            return <span class="MuiTypography-root MuiTypography-body2">{activityType.name}</span>;
    }
    return <span/>;
    
}

ActivityTypeTextField.defaultProps = {
    addLabel: true,
};

const AddressesField = ({record = {}}) => {
    if(record === null || typeof(record) === 'undefined' || record === 'undefined') return <div/>;
    // console.log("Address record: " + JSON.stringify(record));
    let activityType = record.activity_type;
    console.log("Address record activity type: " + activityType);
    if(activityType === ACTIVITY_TYPE_TOURISM)
        return <Fragment>
         <Labeled label="Adresse de départ"/>
        <Typography variant="body2">
            {record.from_address}
        </Typography>
        <Labeled label="Adresse d'arrivée"/>
        <Typography variant="body2">
            {record.to_address}
        </Typography>
        {/* <TextField source="from_address" label = "Adresse de départ"/>
        <TextField source="to_address" label = "Adresse d'arrivée"/> */}
        </Fragment>;

    if(activityType === ACTIVITY_TYPE_DELIVERY)
        return <Fragment>
        {/* <TextField source="from_address" label = "Adresse d'enlèvement"/>
        <TextField source="to_address" label = "Adresse de livraison"/> */}
        <Labeled label="Adresse d'enlèvement"/>
        <Typography variant="body2">
            {record.from_address}
        </Typography>
        <Labeled label="Adresse de livraison"/>
        <Typography variant="body2">
            {record.to_address}
        </Typography>
        </Fragment>;

    if(activityType === ACTIVITY_TYPE_UTILITY)
        return <Fragment>
        {/* <TextField source="from_address" label = "Adresse d'enlèvement"/>
        <TextField source="to_address" label = "Adresse de dépose"/> */}
        <Labeled label="Adresse d'enlèvement"/>
        <Typography variant="body2">
            {record.from_address}
        </Typography>
        <Labeled label="Adresse de dépose"/>
        <Typography variant="body2">
            {record.to_address}
        </Typography>
        </Fragment>;

     return <Fragment>
     <Labeled label="Adresse de départ"/>
    <Typography variant="body2">
        {record.from_address}
    </Typography>
    <Labeled label="Adresse d'arrivée"/>
    <Typography variant="body2">
        {record.to_address}
    </Typography>
    {/* <TextField source="from_address" label = "Adresse de départ"/>
    <TextField source="to_address" label = "Adresse d'arrivée"/> */}
    </Fragment>;
}

const PackageField = ({record = {}}) => {
    if(record === null || typeof(record) === 'undefined' || record === 'undefined') return <div/>;
    // console.log("Address record: " + JSON.stringify(record));
    let activityType = record.activity_type;
    
    if(activityType === ACTIVITY_TYPE_TOURISM)
        return <Fragment>
         <Labeled label="Nombre de passagers"/>
        <Typography variant="body2">
            {record.passenger_count}
        </Typography>
        <p/>
        <Labeled label="Nombre de bagagges"/>
        <Typography variant="body2">
            {record.luggage_count}
        </Typography>
        {/* <TextField source="from_address" label = "Adresse de départ"/>
        <TextField source="to_address" label = "Adresse d'arrivée"/> */}
        </Fragment>;

    if(activityType === ACTIVITY_TYPE_DELIVERY){
        // var extra = JSON.parse(record.extra);
        // console.log("Extra record 1: " + extra);
        // console.log("Extra record 2: " + JSON.stringify(extra));
        // var passenger = JSON.parse(record.passenger);
        return <Fragment>
        {/* <TextField source="from_address" label = "Adresse d'enlèvement"/>
        <TextField source="to_address" label = "Adresse de livraison"/> */}
        <Labeled label="Nombre de colis"/>
        <Typography variant="body2">
            {record.quantity}
        </Typography>
        <p/>
        <Labeled label="Remarques sur la marchandise"/>
        <Typography variant="body2">
            {record.note}
        </Typography>
        <p/>
        <Labeled label="Nom du destinataire"/>
        <Typography variant="body2">
            {record.recipient_name}
        </Typography>
        <p/>
        <Labeled label="Téléphone du destinataire"/>
        <p><span class="MuiTypography-root MuiTypography-body2">{record.recipient_phone}</span></p>
        {/* <Typography variant="body2">
            {passenger.phone}
        </Typography> */}

        </Fragment>;
    }


    if(activityType === ACTIVITY_TYPE_UTILITY){
        // extra = JSON.parse(record.extra);
        return <Fragment>
        
        <Labeled label="Commentaires du client"/>
        <Typography variant="body2">
            {record.note}
        </Typography>
        
        </Fragment>;
    }

     return <Fragment/>;
}
// const userId = localStorage.getItem('user_id');
// const role = localStorage.getItem('role');

const ShowOrderClient = ({staticContext, ...props}) => {
    const classes = useStyles();
    return(
    <Show title={<Title />} {...props} actions={<ShowActions />}>
            
        <SimpleShowLayout>
        <OrderStatusField source="status" label = "État" className={classes.colorPrimary}/>
        <FormattedDateField source="create_time" format="format_date_time" label="Date/heure"/>
            <TextField source="order_number" label="Nº de commande"/>
            {/* <ActivityTypeTextField label="Type de service"/> */}
            <OrderTypeField source="order_type" label= "Type de commande"/>
            <FormattedDateField source="pick_time" format="format_date" label="Date de livraison"/>
            <TimeSlotTextField label="Créneau de livraison"/>
            {/* <ReferenceField label="Type de véhicule" source="vehicle_type_id" 
            reference="vehicle_type" link="">
                    <TextField source="name" />
                </ReferenceField> */}
            {/* <OrderActivityTypeField source="order_type" label= "Type d'activité"/> */}
            {/* <TextField source="c_last_name" label="Nom du client"/>
            <TextField source="c_first_name" label="Prénom du client"/> */}
            
            {/* <TextField source="from_address" label="Adresse de départ"/> */}
            <TextField source="to_address" label="Adresse de livraison"/>
            {/* <TextField source="d_last_name" label="Nom du chauffeur"/>
            <TextField source="d_first_name" label="Prénom du chauffeur"/> */}
            <FullNameDriverField label="Livreur"/>
            <TextField source="note" label="Commentaire pour le livreur"/>
            {/* <TextField source="total" label="Prix(€)"/> */}
            
                <Divider/>
                
                <br/>
                <Typography style={{ fontWeight: 600, fontSize:15 }}>Produits</Typography>
            <ReferenceManyField reference="product" target="order_id" addLabel={false}>
                        <Datagrid rowClick="">
                        <TextField source="name" label = "Titre du produit"/>
                <TextField source="quantity" label = "Quantité"/>
                <TextField source="price" label="Prix TTC (€)"/>
                        </Datagrid>
                    </ReferenceManyField>

                    <br/>
                {/* <Divider/> */}
                {/* <br/> */}
                {/* <TextField source="original_total" label="Montant sans frais d'attente(€)"/> */}
                {/* <TextField source="wait_amount" label="Frais d'attente(€)" className={classes.inlineBlock}/> */}
                <Typography style={{ fontWeight: 600, fontSize:15 }}>Montants</Typography>
                
                <TextField source="product_amount" label="Produits(€)" className={classes.inlineBlock}/>
                <TextField source="ship_amount" label="Frais de livraison(€)" className={classes.inlineBlock}/>
                
                <TextField source="total" label="Total(€)" className={classes.inlineBlock}/>
                
                <Divider/>
                <br/>
                {/* <Typography variant="body2" color="primary">Déroulement</Typography> */}
                <Typography style={{ fontWeight: 600, fontSize:15 }}>Déroulement</Typography>
                <div/>
                {/* <div/>
                <TextField source="wait_min" label="Temps d'attente(mn)"/> */}
                {/* <FormattedDateField source="start_time" format="format_date_time" label="Heure de début"/> */}
                <div/>
                <FormattedDateField source="accept_time" format="format_date_time" label="Acceptée à" className={classes.inlineBlock}/>
                <FormattedDateField source="start_time" format="format_date_time" label="Traitée à" className={classes.inlineBlock}/>
                {/* <FormattedDateField source="end_time" format="format_date_time" label="Heure d'arrivée" className={classes.inlineBlock}/> */}
                
                {/* <FormattedDateField source="end_time" format="format_date_time" label="Heure d'arrivée"/> */}
                <FormattedDateField source="cancel_time" format="format_date_time" label="Annulée à" className={classes.inlineBlock}/>
                
        </SimpleShowLayout>
    </Show>
)};


export default ShowOrderClient;