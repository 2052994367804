
import React from 'react';
import { number, DateInput, BooleanInput,ReferenceManyField, Datagrid, Pagination, FormDataConsumer, ImageInput, ImageField, email, RadioButtonGroupInput, TextField, FormTab, TabbedForm, SelectInput, TextInput, Edit, SimpleForm, required, useTranslate } from 'react-admin';
import CardActions from '@material-ui/core/CardActions';
import { ListButton, Toolbar, SaveButton, DeleteButton } from 'react-admin';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import DateFnsUtils from '@date-io/date-fns';
// import { DateInput } from 'react-admin-date-inputs2';
import MomentUtils from 'material-ui-pickers/utils/moment-utils';
import frLocale from "date-fns/locale/fr";
import Typography from '@material-ui/core/Typography';

import {GENDER_MALE, GENDER_FEMALE, USER_TYPE_AUTO_ENTERPRENER, USER_TYPE_COMPANY_AND_DRIVER, UB_TYPE_EXECUTIVE, UB_TYPE_OWNER, UB_TYPE_DIRECTOR} from '../../util/constant';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { FormattedDateField } from '../../control/field';
import { BACKEND_FILE_URL } from '../../util/variant';
import { ImageViewer } from '../../control/viewer';
import { ManagerCreateButton, ManagerDeleteButton, ManagerEditButton } from '../../control/button';

DateFnsUtils.prototype.getStartOfMonth = DateFnsUtils.prototype.startOfMonth

const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

const CustomToolbarNoStyle = ({permissions, ...props}) => (
    <Toolbar {...props}>
        <SaveButton />
        {/* <DeleteButton label="ra.action.delete" redirect='list' {...props}/> */}
    </Toolbar>
);

const CustomToolbar = withStyles(toolbarStyles)(CustomToolbarNoStyle);

const cardActionStyle = {
    zIndex: 2,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    padding: 0,
};

const validateEmail = [required(), email()];

const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
    colorLabel: {
        // paddingLeft: 50,
        color: '#F4004E',
    },

    noColorLabel: {
        paddingLeft: 50,  
    },

    colorValue: {
        color: '#F4004E',
        paddingLeft:5
    },

    supplementText: {
        color: '#F4004E',
    },

    noHeader:{
        display:'none',
        // height:'1px'
    }
    
});

const styles = {
    flex: { display: 'flex', },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    flexRow: { display: 'flex', flexDirection: 'row' },
    leftCol: { flex: 1, marginRight: '1em' },
    rightCol: { flex: 1, marginLeft: '1em' },
    singleCol: { marginTop: '2em', marginBottom: '2em' },
    map:{ flex: 1, marginTop: '1em', marginBottom: '1em' },
    button:{ flex: 1, marginTop: '1em'},
};


const EditActions = ({ basePath, data, resource }) => (
    <CardActions style={cardActionStyle}>
        <ListButton basePath={basePath}/>
    </CardActions>
);
const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.driver_company.edit')}: {record ? `${record.email}` : ''}</span>;
};

var genders = [];
genders.push({id: GENDER_MALE, name: 'Monsieur'});
genders.push({id: GENDER_FEMALE, name: "Madame"});

var driverTypes = [];
driverTypes.push({id: USER_TYPE_AUTO_ENTERPRENER, name: "Auto-entrepreneur"});
driverTypes.push({id: USER_TYPE_COMPANY_AND_DRIVER, name: "Autre"});

const ManagerDocContentPanel = ({ id, record, resource }) => {
    const classes = useStyles();
    console.log("Manager record " + JSON.stringify(record));
    if(record === null || record ==='undefined' || typeof(record) === 'undefined') return <div/>;
    var idCardRectoUrl = record.id_card_recto_url;
    if(idCardRectoUrl !== '' && !idCardRectoUrl.startsWith("http://") && !idCardRectoUrl.startsWith("https://"))
    idCardRectoUrl = BACKEND_FILE_URL + idCardRectoUrl;

    var idCardVersoUrl = record.id_card_verso_url;
    if(idCardVersoUrl !== '' && !idCardVersoUrl.startsWith("http://") && !idCardVersoUrl.startsWith("https://"))
    idCardVersoUrl = BACKEND_FILE_URL + idCardVersoUrl;

    var addressProofRectoUrl = record.address_proof_recto_url;
    if(addressProofRectoUrl !== '' && !addressProofRectoUrl.startsWith("http://") && !addressProofRectoUrl.startsWith("https://"))
    addressProofRectoUrl = BACKEND_FILE_URL + addressProofRectoUrl;
    
    
    var files = [];
    files[0] = {file_url: idCardRectoUrl, name: "Carte d'identité recto"};
    files[1] = {file_url: idCardVersoUrl, name: "Carte d'identité verso"};
    files[2] = {file_url: addressProofRectoUrl, name: 'Justificatif de domicile'};
    
    return(
       
            <div style={styles.flexRow}>
                <div style={styles.images}>        
                    {files.map(file => {
                        return <p><ImageViewer files={files} fileUrl={file.file_url} title={file.name} width='300' height='300'/><br/></p>
                        {/* return <ImageViewer files={files} fileUrl={file.file_url} title={file.name} width='300' height='300'/> */}
                    })}
                </div>
                {/* <VehicleDateField record={record}/> */}
                {/* <div className={classes.noColorLabel}><span>Actions: </span>
                {console.log("Vehicle is locked = " + record.is_locked)}
                {record.is_locked === Status.UNLOCKED && <VehicleLockButton record={record}/>}
                {record.is_locked === Status.LOCKED && <VehicleUnlockButton record={record}/>}
                {record.status == DataStatus.NEW && 
                <VehicleApproveButton record={record}/>}
                {record.status == DataStatus.NEW && 
                <VehicleRejectButton record={record}/>}
                </div> */}
            </div>
    );
};

const ManagerFields = ({record, basePath}) => {
    const translate = useTranslate();
    if(record.user_type === USER_TYPE_COMPANY_AND_DRIVER)
    return (<div><ManagerCreateButton title="Ajouter un Cadre" type={UB_TYPE_EXECUTIVE} translate={translate}/>
    <BooleanInput source="executive_done" label= "La saisie des Cadres est complétée" style={{'width':'600px'}}/>
        <Typography style={{ fontWeight: 600, fontSize:15 }}>Cadres</Typography>
        <ReferenceManyField basePath={basePath} pagination={<Pagination basePath={basePath}/>} reference="customer_manager" target="driver_id" addLabel={false} filter={{'type': UB_TYPE_EXECUTIVE}}>
        <Datagrid expand={<ManagerDocContentPanel/>} expandHeader={<div/>}>
                
                <TextField label="Nom" source="last_name"/>
                <TextField label="Prénom" source="first_name"/>
                <FormattedDateField label="De de naissance" source="birth_date"/>
                <TextField label="Adresse" source="address"/>
                <TextField label="Code postal" source="postal_code"/>
                <TextField label="Ville" source="city"/>
                <ManagerEditButton title="Editer un Cadre" translate={translate}/>
                <ManagerDeleteButton content="Voulez-vous supprimer ce Cadre ?" resource="customer_manager"/>
            </Datagrid>
        </ReferenceManyField>
        

        <ManagerCreateButton title="Ajouter un Directeur général" type={UB_TYPE_DIRECTOR} translate={translate}/>
        <BooleanInput source="director_done" label= "La saisie des Directeurs généraux est complétée" style={{'width':'600px'}}/>
        <Typography style={{ fontWeight: 600, fontSize:15 }}>Directeur Général</Typography>
        <ReferenceManyField basePath={basePath} pagination={<Pagination basePath={basePath}/>} reference="customer_manager" target="driver_id" addLabel={false} filter={{'type': UB_TYPE_DIRECTOR}}>
        <Datagrid expand={<ManagerDocContentPanel/>} expandHeader={<div/>}>
                
                <TextField label="Nom" source="last_name"/>
                <TextField label="Prénom" source="first_name"/>
                <FormattedDateField label="De de naissance" source="birth_date"/>
                <TextField label="Adresse" source="address"/>
                <TextField label="Code postal" source="postal_code"/>
                <TextField label="Ville" source="city"/>
                <ManagerEditButton title="Editer un Directeur général" translate={translate}/>
                <ManagerDeleteButton content="Voulez-vous supprimer ce Directeur général ?" resource="customer_manager"/>
            </Datagrid>
        </ReferenceManyField>
        
        <ManagerCreateButton title="Ajouter un associé" type={UB_TYPE_OWNER} translate={translate}/>
        <BooleanInput source="owner_done" label= "La saisie des Associés est complétée" style={{'width':'600px'}}/>
        <Typography style={{ fontWeight: 600, fontSize:15 }}>Associés</Typography>
        <ReferenceManyField basePath={basePath} pagination={<Pagination basePath={basePath}/>} reference="customer_manager" target="driver_id" addLabel={false} filter={{'type': UB_TYPE_OWNER}}>
        <Datagrid expand={<ManagerDocContentPanel/>} expandHeader={<div/>}>
                
                <TextField label="Nom" source="last_name"/>
                <TextField label="Prénom" source="first_name"/>
                <FormattedDateField label="De de naissance" source="birth_date"/>
                <TextField label="Adresse" source="address"/>
                <TextField label="Code postal" source="postal_code"/>
                <TextField label="Ville" source="city"/>
             
                <ManagerEditButton title="Editer un Associé" translate={translate}/>
                
                <ManagerDeleteButton content="Voulez-vous supprimer cet Associé ?" resource="customer_manager"/>
            </Datagrid>
        </ReferenceManyField></div>);
        return <div/>;
}

// const ManagerCreateButtonPrv = (record) => ManagerCreateButton
const validatePostalCodeRange = (value, allValues) => {
  if (value < 1000 || value > 99000) {
      return 'Doit être en [01000, 99000]';
  }
  return [];
};

const validatePostalCode = [required(), number(), validatePostalCodeRange];

const EditDriver = ({permissions, ...props })  => {
    const classes = useStyles();
    const translate = useTranslate();
    const {basePath} = props;
    // const classes = useStyles();
    const uploadFileText = translate('ra.input.file.upload_single') + '(' + translate('max_size') + '5Mo)';

    return(
    <Edit title={<Title />} {...props} actions={<EditActions/>}>
         <SimpleForm redirect="show" 
                toolbar={<CustomToolbar/>}>
                <TextInput source="email" label="Email" disabled style={{'width':'600px'}}/>
                
                <Typography style={{ fontWeight: 600, fontSize:15 }}>Infos de la société</Typography>
            <TextInput source="company_name" label="Raison sociale" validate={required()} style={{'width':'600px'}}/>
            <TextInput source="company_address" label="Adresse" validate={required()} style={{'width':'600px'}}/>
            <TextInput source="postal_code" label="Code postal" validate={required()}/>
            <TextInput source="city" label="Ville" validate={required()}/>
            <TextInput source="mobile_dial_code" label="Indicatif du pays (E.x 33)" defaultValue={'33'} validate={required()} style={{'width':'600px'}}/>
            <TextInput source="mobile" label="Téléphone" validate={required()}/>
            {/* <TextInput source="email" label="Email" validate={validateEmail} style={{'width':'600px'}}/> */}
            
            <RadioButtonGroupInput source="user_type" label="Type de société" choices={[
                 { id: USER_TYPE_AUTO_ENTERPRENER, name: 'Auto-entrepreneur' }, { id: USER_TYPE_COMPANY_AND_DRIVER, name: "Autre" },
            ]} validate={required()} disabled/>

            <TextInput source="siren_number" label="Numéro de SIRET" validate={required()}/>
            
            <FormDataConsumer>
                {({ formData, ...rest }) => formData.user_type === USER_TYPE_COMPANY_AND_DRIVER &&
                <TextInput source="vat_no" label="Numéro TVA Intracommunautaire" validate={required()} style={{'width':'600px'}}/>
                     
                }
            </FormDataConsumer>
            
            <TextInput source="iban" label="IBAN" validate={required()}/>
            {/* <PasswordInput source="password" label="Mot de passe" validate={required()} style={{'width':'600px'}}/>
            <FormDataConsumer>
                {({ formData, ...rest }) =>
                    <PasswordInput {...rest} source="password_confirm" label="Mot de passe confirmé" validate={validatePassword} style={{'width':'600px'}}/>
                     
                }
            </FormDataConsumer> */}

            <Typography style={{ fontWeight: 600, fontSize:15 }}>Documents d'activité</Typography>
            <ImageInput source="rib" label="RIB" accept="image/*" maxSize='5000000' placeholder={uploadFileText}>
                <ImageField source="url" title="Fichier" />
            </ImageInput>

            <ImageInput source="kbis" label="Extrait KBIS" accept="image/*" maxSize='5000000' placeholder={uploadFileText}>
                <ImageField source="url" title="Fichier" />
            </ImageInput>

            <ImageInput source="company_status" label="Statut de la société" accept="image/*" maxSize='5000000' placeholder={uploadFileText}>
                <ImageField source="url" title="Fichier" />
            </ImageInput>


            <ImageInput source="rcp" label="Assurance responsabilité civile" accept="image/*" maxSize='5000000' placeholder={uploadFileText}>
                <ImageField source="url" title="Fichier" />
            </ImageInput>

            {/* <Typography variant="body2">Représentant légal:</Typography> */}
            <br/>
            <Typography style={{ fontWeight: 600, fontSize:15 }}>Représentant légal</Typography>
            <TextInput source="manager_last_name" label="Nom du représentant légal" validate={required()} style={{'width':'600px'}}/>
            <TextInput source="manager_first_name" label="Prénom du représentant légal" validate={required()} style={{'width':'600px'}}/>
            <MuiPickersUtilsProvider utils={MomentUtils}>
            {/* <DateInput source="birth_day" label="Date de naissance"
            autoComplete="" options={{ format: 'dd/MM/yyyy' }} 
                 providerOptions={{ utils: DateFnsUtils, locale: frLocale }}
            /> */}

            <DateInput source="manager_birth_date" label="Date de naissance"
                    options={{ format: 'DD/MM/YYYY' }} 
                        providerOptions={{ utils: DateFnsUtils, locale: frLocale }}
                        validate={required()} style={{'width':'600px'}}/>


            </MuiPickersUtilsProvider>
            <TextInput source="manager_address" label="Adresse du représentant légal" validate={required()} style={{'width':'600px'}}/>
            <TextInput source="manager_postal_code" label="Code postal du représentant légal" validate={required()} style={{'width':'600px'}}/>
            <TextInput source="manager_city" label="Ville du représentant légal" validate={required()} style={{'width':'600px'}}/>

            <FormDataConsumer>
                {({ formData, ...rest }) => formData.user_type === USER_TYPE_COMPANY_AND_DRIVER &&
                <RadioButtonGroupInput source="manager_sub_type" label="Rôle dans la société" choices={[
                 { id: UB_TYPE_EXECUTIVE, name: 'Cadre' }, { id: UB_TYPE_OWNER, name: "Associé (si les parts >= 25%" },
            ]} validate={required()} />
                     
                }
            </FormDataConsumer>

            <ImageInput source="manager_id_card_recto" label="Pièce d'identité recto du représentant légal" accept="image/*" maxSize='5000000' placeholder={uploadFileText}>
                <ImageField source="url" title="Fichier" />
            </ImageInput>

            <ImageInput source="manager_id_card_verso" label="Pièce d'identité verso du représentant légal" accept="image/*" maxSize='5000000' placeholder={uploadFileText}>
                <ImageField source="url" title="Fichier" />
            </ImageInput>


            <ImageInput source="manager_address_proof_recto" label="Justificatif de domicile du représentant légal" accept="image/*" maxSize='5000000' placeholder={uploadFileText}>
                <ImageField source="url" title="Fichier" />
            </ImageInput>

            <br/>
            <ManagerFields/>
            {/* <FormDataConsumer>
                {({ formData, ...rest }) => formData.user_type === USER_TYPE_COMPANY_AND_DRIVER &&
                <div> */}
                {/* <ManagerCreateButton title="Ajouter un Cadre" type={UB_TYPE_EXECUTIVE} translate={translate}/>
            <BooleanInput source="executive_done" label= "La saisie des Cadres est complétée" style={{'width':'600px'}}/>
                <Typography style={{ fontWeight: 600, fontSize:15 }}>Cadres</Typography>
                <ReferenceManyField pagination={<Pagination />} reference="customer_manager" target="driver_id" addLabel={false} filter={{'type': UB_TYPE_EXECUTIVE}}>
                <Datagrid expand={<ManagerDocContentPanel/>} expandHeader={<div/>}>
                        
                        <TextField label="Nom" source="last_name"/>
                        <TextField label="Prénom" source="first_name"/>
                        <FormattedDateField label="De de naissance" source="birth_date"/>
                        <TextField label="Adresse" source="address"/>
                        <TextField label="Code postal" source="postal_code"/>
                        <TextField label="Ville" source="city"/>
                        <ManagerEditButton title="Editer un Cadre" translate={translate}/>
                        <ManagerDeleteButton content="Voulez-vous supprimer ce Cadre ?" resource="customer_manager"/>
                    </Datagrid>
                </ReferenceManyField>
                

                <ManagerCreateButton title="Ajouter un Directeur général" type={UB_TYPE_DIRECTOR} translate={translate}/>
                <BooleanInput source="director_done" label= "La saisie des Directeurs généraux est complétée" style={{'width':'600px'}}/>
                <Typography style={{ fontWeight: 600, fontSize:15 }}>Directeur Général</Typography>
                <ReferenceManyField pagination={<Pagination />} reference="customer_manager" target="driver_id" addLabel={false} filter={{'type': UB_TYPE_DIRECTOR}}>
                <Datagrid expand={<ManagerDocContentPanel/>} expandHeader={<div/>}>
                        
                        <TextField label="Nom" source="last_name"/>
                        <TextField label="Prénom" source="first_name"/>
                        <FormattedDateField label="De de naissance" source="birth_date"/>
                        <TextField label="Adresse" source="address"/>
                        <TextField label="Code postal" source="postal_code"/>
                        <TextField label="Ville" source="city"/>
                        <ManagerEditButton title="Editer un Directeur général" translate={translate}/>
                        <ManagerDeleteButton content="Voulez-vous supprimer ce Directeur général ?" resource="customer_manager"/>
                    </Datagrid>
                </ReferenceManyField>
                
                <ManagerCreateButton title="Ajouter un associé" type={UB_TYPE_OWNER} translate={translate}/>
                <BooleanInput source="owner_done" label= "La saisie des Associés est complétée" style={{'width':'600px'}}/>
                <Typography style={{ fontWeight: 600, fontSize:15 }}>Associés</Typography>
                <ReferenceManyField pagination={<Pagination />}  reference="customer_manager" target="driver_id" addLabel={false} filter={{'type': UB_TYPE_OWNER}}>
                <Datagrid expand={<ManagerDocContentPanel/>} expandHeader={<div/>}>
                        
                        <TextField label="Nom" source="last_name"/>
                        <TextField label="Prénom" source="first_name"/>
                        <FormattedDateField label="De de naissance" source="birth_date"/>
                        <TextField label="Adresse" source="address"/>
                        <TextField label="Code postal" source="postal_code"/>
                        <TextField label="Ville" source="city"/>
                     
                        <ManagerEditButton title="Editer un Associé" translate={translate}/>
                        
                        <ManagerDeleteButton content="Voulez-vous supprimer cet Associé ?" resource="customer_manager"/>
                    </Datagrid>
                </ReferenceManyField> */}
                
                {/* </div> */}
                     
                {/* }
            </FormDataConsumer> */}

            <Typography style={{ fontWeight: 600, fontSize:15 }}>Paramètres</Typography>
                <BooleanInput label="Voir commandes du jour" source="today_view"/>
                <BooleanInput label="Voir toutes les commandes" source="all_view"/>
                <BooleanInput label="Recevoir/voir les commandes immédiate" source="immediate_receive"/>
                <BooleanInput label="Recevoir/voir les commandes en réservation" source="reservation_receive"/>
                <BooleanInput label="Recevoir/voir les commandes payées en carte" source="in_card_receive"/>
                <BooleanInput label="Recevoir/voir les commandes payées en espèces" source="in_cash_receive"/>
            
            
        </SimpleForm>
    </Edit>
);
};

export default EditDriver;