import { stringify } from 'query-string';
import {
    fetchUtils,
    GET_LIST,
    GET_ONE,
    GET_MANY,
    GET_MANY_REFERENCE,
    CREATE,
    UPDATE,
    UPDATE_MANY,
    DELETE,
    DELETE_MANY,
} from 'react-admin';

import { BACKEND_FILE_URL, BACKEND_API_KEY, BACKEND_API_KEY_DELIVERY } from '../util/variant'
import { generateRandom } from '../util/function';
import * as Constant from '../util/constant'



function getFullPathFiles(files) {
    if (files !== null && typeof files !== 'undefined') {
        console.log('File list: ' + JSON.stringify(files));
        files.forEach(function (file, index) {
            if (typeof file.url !== 'undefined') {
                if (!file.url.startsWith("http://") && !file.url.startsWith("https://"))
                    file.url = BACKEND_FILE_URL + "/" + file.url;
                if (!file.thumbnail.startsWith("http://") && !file.thumbnail.startsWith("https://"))
                    file.thumbnail = BACKEND_FILE_URL + "/" + file.thumbnail;
                files[index] = file;
            }
        }
            , files);
    }

    return files;
}

function getFileUrl(filePath) {
    if (filePath !== '')
        return BACKEND_FILE_URL + filePath;
    return '';
}

function getFileUrlWithRandom(filePath) {
    if (filePath !== '')
        return BACKEND_FILE_URL + filePath + "?random=" + generateRandom(10);
    return null;
}

/**
 * Maps react-admin queries to a json-server powered REST API
 *
 * @see https://github.com/typicode/json-server
 * @example
 * GET_LIST     => GET http://my.api.url/posts?_sort=title&_order=ASC&_start=0&_end=24
 * GET_ONE      => GET http://my.api.url/posts/123
 * GET_MANY     => GET http://my.api.url/posts/123, GET http://my.api.url/posts/456, GET http://my.api.url/posts/789
 * UPDATE       => PUT http://my.api.url/posts/123
 * CREATE       => POST http://my.api.url/posts/123
 * DELETE       => DELETE http://my.api.url/posts/123
 */
const tzOffset = new Date().getTimezoneOffset() / 60
export default (apiUrl, httpClient = fetchUtils.fetchJson) => {
    /**
     * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
     * @param {String} resource Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params The data request params, depending on the type
     * @returns {Object} { url, options } The HTTP request parameters
     */
    const convertDataRequestToHTTP = (type, resource, params) => {
        let url = '';
        const options = {};
        switch (type) {
            case GET_LIST: {
                const { page, perPage } = params.pagination;
                const { field, order } = params.sort;

                const query = {
                    ...fetchUtils.flattenObject(params.filter),
                    _sort: field,
                    _order: order,
                    _start: (page - 1) * perPage,
                    _end: page * perPage,
                };
                if (resource === "driver_sub") resource = "driver";
                url = `${apiUrl}/${resource}?${stringify(query)}`;
                if (resource === 'turnover') {
                    console.log("Params query: " + stringify(query));
                    const filters = stringify(query).split("&");
                    console.log("Params filters: " + stringify(filters));
                    var productIds = [];
                    for (var i = 0; i < filters.length; i++) {
                        const couple = filters[i].split("=");
                        if (couple[0] === 'product_id') {
                            productIds.push(couple[1]);
                        }
                    }

                    delete query.product_id;
                    delete query.product_ids;

                    const productIdsVal = productIds.join(",");
                    url = `${apiUrl}/${resource}?${stringify(query)}&product_ids=${productIdsVal}`;
                    console.log("Turnover request: " + url);
                }

                break;
            }
            case GET_ONE:
                if (resource === "driver_sub") resource = "driver";
                url = `${apiUrl}/${resource}/${params.id}`;
                break;
            case GET_MANY_REFERENCE: {
                const { page, perPage } = params.pagination;
                const { field, order } = params.sort;

                const query = {
                    ...fetchUtils.flattenObject(params.filter),
                    [params.target]: params.id,
                    _sort: field,
                    _order: order,
                    _start: (page - 1) * perPage,
                    _end: page * perPage,
                };
                if (resource === "driver_sub") resource = "driver";
                url = `${apiUrl}/${resource}?${stringify(query)}`;
                console.log('GET_MANY_REFERENCE: ' + url);
                break;
            }
            case UPDATE: {
                // let role = localStorage.getItem('role');
                let appKey = BACKEND_API_KEY;
                //  if(role === 'Delivery') appKey = BACKEND_API_KEY_DELIVERY;
                if (resource === "driver_company") {
                    params.data.director_done = params.data.director_done ? 1 : 0;
                    params.data.executive_done = params.data.executive_done ? 1 : 0;
                    params.data.owner_done = params.data.owner_done ? 1 : 0;
                }
                params.data.app_key = appKey;
                params.data.tz_offset = tzOffset;

                if (resource === "driver_sub") resource = "driver";
                url = `${apiUrl}/${resource}/${params.id}`;
                options.method = 'PUT';
                options.body = JSON.stringify(params.data);
                console.log('Update body: ' + options.body);
                break;
            }
            case CREATE: {
                //  let role = localStorage.getItem('role');
                let appKey = BACKEND_API_KEY;
                //if(role === 'Delivery') appKey = BACKEND_API_KEY_DELIVERY;
                if (resource === "driver")
                    params.data.company_id = 0;
                else if (resource === "driver_company") {
                    params.data.company_id = 1;
                }


                params.data.app_key = appKey;
                params.data.tz_offset = tzOffset;
                url = `${apiUrl}/${resource}`;
                options.method = 'POST';
                options.body = JSON.stringify(params.data);
                console.log('Create body: ' + options.body);
                break;
            }
            case DELETE: {
                params.data = {};
                //  let role = localStorage.getItem('role');
                let appKey = BACKEND_API_KEY;
                //if(role === 'Delivery') appKey = BACKEND_API_KEY_DELIVERY;
                params.data.app_key = appKey;
                url = `${apiUrl}/${resource}/${params.id}`;
                options.method = 'DELETE';
                options.body = JSON.stringify(params.data);
                console.log('Delete body: ' + options.body);
                break;
            }
            case GET_MANY: {
                if (resource === "driver_sub") resource = "driver";
                const query = {
                    id: params.ids,
                };
                url = `${apiUrl}/${resource}?${stringify(query)}`;
                break;
            }
            default:
                throw new Error(`Unsupported fetch action type ${type}`);
        }

        // console.log('Request: ' + url);

        return { url, options };
    };

    const translateGetManyResponseData = (json, resource) => {
        var data = [];
        if (json !== null && typeof (json) !== 'undefined') {
            data = json['data'];
            if (data == null) return data;

            if (resource === 'driver' || resource === 'driver_sub' || resource === 'client_pro' || resource === 'client' || resource === 'driver_in_company'
                || resource === 'product')
                data = data.map(resource => ({ ...resource, photo_url: getFileUrl(resource.photo_url) }));
            else
                if (resource === 'driver_company' || resource === 'driver_company_deleted')
                    data = data.map(resource => ({ ...resource, photo_url: getFileUrl(resource.photo_url) }));
                else
                    if (resource === 'vehicle_type') {
                        if (data != null)
                            data = data.map(resource => ({
                                ...resource,
                                map_icon: { url: getFileUrl(resource.map_icon_url) },
                                select_icon: { url: getFileUrl(resource.select_icon_url) }
                            }));
                    }
                    else
                        if (resource === 'customer_manager') {
                            if (data != null)
                                data = data.map(resource => ({
                                    ...resource,
                                    id_card_recto: { url: getFileUrl(resource.id_card_recto_url) },
                                    id_card_verso: { url: getFileUrl(resource.id_card_verso_url) },

                                    address_proof_recto: { url: getFileUrl(resource.address_proof_recto_url) },
                                }));
                        }
                        else
                            if (resource === 'driver_online') {
                                if (data != null)
                                    data = data.map(resource => ({
                                        ...resource,
                                        vehicle_map_icon_web: { url: getFileUrl(resource.vehicle_map_icon_web_url) }
                                    }));
                            }
                            else
                                data = data.map(resource => ({ ...resource }));
            // console.log('Translated data: ' + JSON.stringify(data));
        }


        return data;
    };

    const translateGetOneResponseData = (json, resource) => {
        if (json && typeof json !== 'undefined') {
            // var files = json.files
            // if(typeof files !== 'undefined'){
            //     console.log('Files' + JSON.stringify(files));
            //     files.forEach(function(file, index){
            //         if (typeof file.url !== 'undefined'){
            //             if(!file.url.startsWith("http://") && !file.url.startsWith("https://"))
            //                 file.url = BACKEND_URL + "" + file.url;
            //             files[index] = file;
            //         }
            //     }
            //     , files);
            // }
            var data = json['data'];
            if (typeof data !== 'undefined') {
                // data.files = getFullPathFiles(data.files);
                if (resource === 'profile' || resource === 'config') {
                    return { data: { ...data, id: 'noid' } };
                }

                if (resource === 'driver' || resource === 'driver_sub') {
                    data.photo_url = getFileUrlWithRandom(data.photo_url);
                    data.photo = { src: data.photo_url };
                    const docs = data.docs;
                    if (typeof docs !== 'undefined' && docs !== 'undefined' && docs !== null) {
                        docs.forEach(function (doc, index) {

                            if (doc.doc_type === Constant.UB_DOC_TYPE_DRIVING_LICENSE) {
                                const files = doc.files;
                                if (files !== 'undefined' && files !== null && files.length > 0)
                                    data.driving_license = { src: getFileUrl(files[0].file_url), doc_type: doc.doc_type };
                                else
                                    data.driving_license = { doc_type: doc.doc_type };
                            }
                            else
                                if (doc.doc_type === Constant.UB_DOC_TYPE_ID_CARD) {
                                    const files = doc.files;
                                    if (files !== 'undefined' && files !== null && files.length > 0)
                                        data.id_card = { src: getFileUrl(files[0].file_url), doc_type: doc.doc_type };
                                    else
                                        data.id_card = { doc_type: doc.doc_type };
                                }
                                else
                                    if (doc.doc_type === Constant.UB_DOC_TYPE_ID_CARD_RECTO) {
                                        const files = doc.files;
                                        if (files !== 'undefined' && files !== null && files.length > 0)
                                            data.id_card_recto = { src: getFileUrl(files[0].file_url), doc_type: doc.doc_type };
                                        else
                                            data.id_card_recto = { doc_type: doc.doc_type };
                                    }
                                    else
                                        if (doc.doc_type === Constant.UB_DOC_TYPE_ID_CARD_VERSO) {
                                            const files = doc.files;
                                            if (files !== 'undefined' && files !== null && files.length > 0)
                                                data.id_card_verso = { src: getFileUrl(files[0].file_url), doc_type: doc.doc_type };
                                            else
                                                data.id_card_verso = { doc_type: doc.doc_type };
                                        }
                        });
                    }
                }
                else
                    if (resource === 'driver_company') {
                        // data.photo_url = getFileUrlWithRandom(data.photo_url);
                        // data.photo = {src: data.photo_url};
                        data.executive_done = data.executive_done === 1 || data.executive_done === "1";
                        data.owner_done = data.owner_done === 1 || data.owner_done === "1";
                        data.director_done = data.director_done === 1 || data.director_done === "1";

                        const manager = data.manager;
                        if (manager !== null && manager !== 'undefined' && typeof (manager) !== 'undefined') {
                            const managerObj = JSON.parse(manager);
                            data.manager_first_name = managerObj['first_name'];
                            data.manager_last_name = managerObj['last_name'];
                            data.manager_birth_date = managerObj['birth_date'];
                            data.manager_address = managerObj['address'];
                            data.manager_postal_code = managerObj['postal_code'];
                            data.manager_city = managerObj['city'];
                            data.manager_sub_type = managerObj['sub_type'];
                            data.manager_id_card_recto = { url: getFileUrl(managerObj['id_card_recto_url']), doc_type: Constant.UB_DOC_TYPE_ID_CARD_RECTO };
                            data.manager_id_card_verso = { url: getFileUrl(managerObj['id_card_verso_url']), doc_type: Constant.UB_DOC_TYPE_ID_CARD_VERSO };
                            data.manager_address_proof_recto = { url: getFileUrl(managerObj['address_proof_recto_url']), doc_type: Constant.UB_DOC_TYPE_ADDRESS_PROOF_RECTO };
                        }
                        const docs = data.docs;
                        if (typeof docs !== 'undefined' && docs !== 'undefined' && docs !== null) {
                            docs.forEach(function (doc, index) {

                                if (doc.doc_type === Constant.UB_DOC_TYPE_RIB) {
                                    const files = doc.files;
                                    if (files !== 'undefined' && files !== null && files.length > 0)
                                        data.rib = { url: getFileUrl(files[0].file_url), doc_type: doc.doc_type };
                                    else
                                        data.rib = { doc_type: doc.doc_type };
                                }
                                else
                                    if (doc.doc_type === Constant.UB_DOC_TYPE_KBIS) {
                                        const files = doc.files;
                                        if (files !== 'undefined' && files !== null && files.length > 0)
                                            data.kbis = { url: getFileUrl(files[0].file_url), doc_type: doc.doc_type };
                                        else
                                            data.kbis = { doc_type: doc.doc_type };
                                    }
                                    else
                                        if (doc.doc_type === Constant.UB_DOC_TYPE_RCP) {
                                            const files = doc.files;
                                            if (files !== 'undefined' && files !== null && files.length > 0)
                                                data.rcp = { url: getFileUrl(files[0].file_url), src: getFileUrl(files[0].file_url), doc_type: doc.doc_type };
                                            else
                                                data.rcp = { doc_type: doc.doc_type };
                                        }
                                        else
                                            if (doc.doc_type === Constant.UB_DOC_TYPE_COMPANY_STATUS) {
                                                const files = doc.files;
                                                if (files !== 'undefined' && files !== null && files.length > 0)
                                                    data.company_status = { url: getFileUrl(files[0].file_url), doc_type: doc.doc_type };
                                                else
                                                    data.company_status = { doc_type: doc.doc_type };
                                            }

                            });
                        }
                    }
                    else
                        if (resource === 'customer_manager') {
                            data.id_card_recto_url = getFileUrlWithRandom(data.front_image_url);
                            data.id_card_recto = { url: data.front_image_url };

                            data.id_card_verso_url = getFileUrlWithRandom(data.id_card_verso_url);
                            data.id_card_verso = { url: data.id_card_verso_url };

                            data.address_proof_recto_url = getFileUrlWithRandom(data.address_proof_recto_url);
                            data.address_proof_recto = { url: data.address_proof_recto_url };
                        }

                        else
                            if (resource === 'vehicle') {
                                data.front_image_url = getFileUrlWithRandom(data.front_image_url);
                                data.front_image = { src: data.front_image_url };

                                data.rear_image_url = getFileUrlWithRandom(data.rear_image_url);
                                data.rear_image = { src: data.rear_image_url };

                                data.gray_card_recto_url = getFileUrlWithRandom(data.gray_card_recto_url);
                                data.gray_card_recto = { src: data.gray_card_recto_url };

                                data.gray_card_verso_url = getFileUrlWithRandom(data.gray_card_verso_url);
                                data.gray_card_verso = { src: data.gray_card_verso_url };
                            }
                            else
                                if (resource === 'client') {
                                    data.photo_url = getFileUrlWithRandom(data.photo_url);
                                }
                                else if (resource === 'product') {
                                    data.photo = { url: getFileUrl(data.photo_url) };
                                    data.photo_url = getFileUrl(data.photo_url);

                                    // delete data.photo_url;

                                    // console.log('Vehicle type data: ' + JSON.stringify(data));
                                }
                                else if (resource === 'vehicle_type') {
                                    data.map_icon = { url: getFileUrl(data.map_icon_url) };
                                    data.select_icon = { url: getFileUrl(data.select_icon_url) };

                                    delete data.map_icon_url;
                                    delete data.select_icon_url;

                                    // console.log('Vehicle type data: ' + JSON.stringify(data));
                                }
                                else
                                    if (resource === 'driver_online') {
                                        if (data != null) {
                                            data.vehicle_map_icon_web = { url: getFileUrl(data.vehicle_map_icon_web_url) };
                                            delete data.vehicle_map_icon_web_url;
                                        }

                                    }

                if (data.id != null)
                    data = { data: { ...data, id: data.id } };
                else
                    data = { data: { ...data } };

                return data;
            }

            return {};
        }

        return { data: { ...{}, id: '' } };
    };

    /**
     * @param {Object} response HTTP response from fetch()
     * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
     * @param {String} resource Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params The data request params, depending on the type
     * @returns {Object} Data response
     */
    const convertHTTPResponse = (response, type, resource, params) => {
        const { headers, json } = response;
        console.log('Response: ' + JSON.stringify(json));
        var data;
        switch (type) {
            case GET_LIST:
            case GET_MANY_REFERENCE:
                if (!headers.has('x-total-count')) {
                    throw new Error(
                        'The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?'
                    );
                }

                // if(json !== null && typeof(json) !== 'undefined'){
                //     data = json['data'];
                //     if(resource === 'driver' || resource === 'client')
                //         data = data.map(resource => ({ ...resource, photo_url: getFileUrl(resource.photo_url) }));
                //     else
                //         data = data.map(resource => ({ ...resource}));
                // }
                // else
                //     data = [];
                data = translateGetManyResponseData(json, resource);
                return {
                    // data: json,
                    // data: { ...json, id: json._id },
                    data: data,
                    total: parseInt(
                        headers
                            .get('x-total-count')
                            .split('/')
                            .pop(),
                        10
                    ),
                };

            case GET_MANY:
                if (!headers.has('x-total-count')) {
                    throw new Error(
                        'The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?'
                    );
                }

                // if(resource !== null && typeof(resource) !== 'undefined'){
                //     data = json['data'];
                //     data = json.map(resource => ({ ...resource, id: resource.id }));
                // }
                // else
                //     data = json.map(resource => ({ ...resource}));
                // if(json !== null && typeof(json) !== 'undefined'){
                //     data = json['data'];

                //     if(resource === 'driver' || resource === 'client')
                //         data = data.map(resource => ({ ...resource, photo_url: getFileUrl(resource.photo_url) }));
                //     else
                //         data = data.map(resource => ({ ...resource}));
                // }
                // else
                //     data = [];

                data = translateGetManyResponseData(json, resource);

                return {
                    // data: json,
                    // data: { ...json, id: json._id },
                    data: data,
                    total: parseInt(
                        headers
                            .get('x-total-count')
                            .split('/')
                            .pop(),
                        10
                    ),
                };

            case CREATE:
                // return { data: { ...params.data, id: json.id } };
                return { data: { ...params.data, id: json['data'].id } };
            case UPDATE:
            case DELETE:
            case GET_ONE:
            default:
                // return { data: json };
                // console.log('Get data: ' + JSON.stringify(json));
                // if(json && typeof json !== 'undefined'){
                //     // var files = json.files
                //     // if(typeof files !== 'undefined'){
                //     //     console.log('Files' + JSON.stringify(files));
                //     //     files.forEach(function(file, index){
                //     //         if (typeof file.url !== 'undefined'){
                //     //             if(!file.url.startsWith("http://") && !file.url.startsWith("https://"))
                //     //                 file.url = BACKEND_URL + "" + file.url;
                //     //             files[index] = file;
                //     //         }
                //     //     }
                //     //     , files);
                //     // }
                //     data = json['data'];
                //     data.files = getFullPathFiles(data.files);
                //     if(resource === 'profile' || resource === 'config' ){
                //         return {data:{ ...data, id: 'noid'}};
                //     }

                //     if(resource === 'driver' || resource === 'client'){
                //         data.photo_url = getFileUrl(data.photo_url);
                //     }

                //     return {data:{ ...data, id: data.id }};
                // }

                // return {data:{ ...json['data'], id: ''}};
                return translateGetOneResponseData(json, resource);
        }
    };

    /**
     * @param {string} type Request type, e.g GET_LIST
     * @param {string} resource Resource name, e.g. "posts"
     * @param {Object} payload Request parameters. Depends on the request type
     * @returns {Promise} the Promise for a data response
     */
    return (type, resource, params) => {
        // json-server doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
        if (type === UPDATE_MANY) {
            var body = params.data;
            delete body.id;
            console.log("Update many body: " + JSON.stringify(body));

            return Promise.all(
                params.ids.map(id =>
                    httpClient(`${apiUrl}/${resource}/${id}`, {
                        method: 'PUT',
                        body: JSON.stringify(body),
                    })
                )
            ).then(responses => ({
                data: responses.map(response => response.json),
            }));
        }
        // json-server doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
        if (type === DELETE_MANY) {
            return Promise.all(
                params.ids.map(id =>
                    httpClient(`${apiUrl}/${resource}/${id}`, {
                        method: 'DELETE',
                    })
                )
            ).then(responses => ({
                data: responses.map(response => response.json),
            }));
        }
        const { url, options } = convertDataRequestToHTTP(
            type,
            resource,
            params
        );
        return httpClient(url, options).then(response =>
            convertHTTPResponse(response, type, resource, params)
        );
    };
};
